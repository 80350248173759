import React from 'react'
import "./BigText.scss";

const BigText = ({ children, text, className, }) => {
    return (
        <>
            <p className={`big_text ${className || ""}`}>{children || text}</p>
        </>
    )
}

export default BigText