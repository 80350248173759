import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import LottieAnimation from "../../common/LottieAnimation/LottieAnimation";
import animation from "../../../assets/animations/contact-us.json";
import clouds from "../../../assets/animations/contact-us-clouds.json";
import {
  FacebookIcon,
  InstagramIcon,
  PhoneIcon,
  WhatsappIcon,
} from "../../../assets/images/svgs/svgicons";
import { SOCIAL_LINKS } from "../../../utils/constants";
import "./ContactUs.scss";
import ContactForm from "./ContactForm/ContactForm";
import SectionHeading from "../../common/SectionHeading/SectionHeading";

const ContactUs = () => {
  return (
    <section className="contact_sec">
      <Container>
        <Row>
          <Col md={6}>
            <div className="contact_left">
              <div className="contact_animate">
                <LottieAnimation animation={animation} />
                <LottieAnimation
                  animation={clouds}
                  className="clouds_animate"
                />
              </div>
              <div className="contact_social">
                <h3>Follow us on Social Media</h3>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={SOCIAL_LINKS.INSTAGRAM}
                    >
                      <InstagramIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={SOCIAL_LINKS.FACEBOOK}
                    >
                      <FacebookIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={SOCIAL_LINKS.WHATSAPP}
                    >
                      <WhatsappIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`tel:${SOCIAL_LINKS.PHONE}`}
                    >
                      <PhoneIcon /> <span>1</span>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`tel:${SOCIAL_LINKS.PHONE_SECOND}`}
                    >
                      <PhoneIcon /> <span>2</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <SectionHeading
              heading="Get In Touch"
              className="headings"
              subText={[
                "24/7 We will answer your questions and problems"
              ]}
            />
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactUs;
