import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import banner1 from "../../../assets/images/first.jpg";
import banner2 from "../../../assets/images/s.jpg";
import banner3 from "../../../assets/images/third.jpg";
// import banner4 from "../../../assets/images/fourth.webp";
// import banner5 from "../../../assets/images/fifth.jpg";
import service1 from "../../../assets/images/service1.png";
import service2 from "../../../assets/images/service2.png";
import service3 from "../../../assets/images/service3.png";
import service4 from "../../../assets/images/service4.png";
import service5 from "../../../assets/images/service5.png";
import mentor from "../../../assets/images/mentor.png";
import Rn from "../../../assets/images/Rn.png";
import result from "../../../assets/images/resuklt.png";
import approach from "../../../assets/images/approach.png";
import interactive from "../../../assets/images/interactive.png";
import { NavigateIcon } from "../../../assets/images/svgs/svgicons";
import Button from "../../common/Button/Button";
import ContactUs from "../../ui/ContactUs/ContactUs";
import "./Homepage.scss";
import { Link } from "react-router-dom";

const Homepage = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };

  const data = [
    {
      heading: "Career Coaching",
      img: service1,
      info: "Not sure about your next career move? This was meant to find you. We understand that every nurse's path is unique...",
      text: "View More...",
    },
    {
      heading: "NCLEX-RN/PN/RexPN preparation",
      img: service2,
      info: "Ready to conquer the NCLEX-RN/PN or RexPN? Our online interactive complete reviews to ensure your success!",
      text: "View More...",
    },
    {
      heading: "IELTS/CELBAN/Other Language exams",
      img: service3,
      info: "It can be challenging meeting the registration requirements.. we got your back! Learn more …",
      text: "View More...",
    },
    {
      heading: "Resume building/Interview preparation",
      img: service4,
      info: "Do you know the secret to land your dream job? Yes you are right.. a strong resume and a relevant cover letter goes a long way. Learn more!",
      text: "View More...",
    },
    {
      heading: "Recruitment support",
      img: service5,
      info: "Your journey doesn’t end with your RN/LPN licence. Learn how we can support you land your dream job!",
      text: "View More...",
    },
  ];

  const datafy = [
    {
      title: "Expert Mentorship",
      img: mentor,
      text: "Provided by our Canadian registered advanced practice nurses excelling in various specialties to those aspiring to excel North America's healthcare landscape.",
    },
    {
      title: "Experienced Canadian RNs",
      img: Rn,
      text: "With over 15 years of exceptional experience, our team guides and empower nurses worldwide on their career journey.",
    },
    {
      title: "Results oriented",
      img: result,
      text: "We prioritize your success and growth in the North American nursing industry.",
    },
    {
      title: "Client Centred Approach",
      img: approach,
      text: "We tailor our services to meet your unique needs and aspirations of our clients .                                                                                               ",
    },
    {
      title: "Interactive Learning",
      img: interactive,
      text: "Engage in interactive study sessions that foster collaborative learning and skill development for a thriving a successful nursing career.",
    },
  ];

  return (
    <>
      <section className="banner_sec">
        <div className="banner_sec_inner">
          <div className="banner_sec_inner_content">
            <h4>The niche nursing consultancy Inc.</h4>
            <h1>
              Guiding Nurses to Their Niche of Excellence, Where Dreams Blossom
              and Careers Soar.
            </h1>
            {/* <p>
                    We’re glad you’re here! We, at The Niche Nursing, are
                    passionate to support your next career move and your
                    professional career journey.
                  </p> */}
            <Link to="/contact-us">
              <Button img={<NavigateIcon />}>Contact Us </Button>
            </Link>
          </div>
          <Slider {...settings}>
            <div>
              <img src={banner1} alt="" />
            </div>
            <div>
              <img src={banner2} alt="" />
            </div>
            <div>
              <img src={banner3} alt="" />
            </div>
            {/* <div>
              <img src={banner4} alt="" />
            </div> */}
            {/* <div>
                    <img src={banner5} alt="" />
                  </div> */}
          </Slider>
        </div>
      </section>
      <section className="who_sec">
        <Container>
          <Row>
            <Col>
              <h2>Who we are</h2>
              <p>
                Welcome to The Niche Nursing Consultancy Inc., a registered
                Canadian organization located in the Greater Toronto Area
                (GTA)/Toronto, Canada. We are a team of Canadian registered
                nurses experienced in various nursing specialities who are
                committed to mentoring and guiding both novice and seasoned
                nurses as they advance their careers in various specialties.
                With our unwavering support, we guide you step-by-step through
                the abundance of opportunities available, ensuring you navigate
                the path that leads to a rewarding nursing journey. Our services
                cater to a diverse range of needs, whether you are an aspiring
                nurse looking to embark on your career or an expert advanced
                practice nurse seeking further growth and development.
              </p>
              <p className="mt-4">
                At The Niche Nursing Consultancy, we are dedicated to standing
                by your side and assisting you at every stage of your career,
                helping you achieve the success and fulfillment you deserve.
                Embrace the possibilities with us as we pave the way to your
                thriving nursing career in Canada and beyond.
              </p>
              <Link to="/about-us">
                <Button className="mt-4 mb-5">View More...</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="services_sec">
        <Container>
          <h2>Services</h2>
        </Container>
        <section className="services_sec_inner">
          <Container>
            <Row className="justify-content-center">
              {data.map((item, index) => {
                return (
                  <Col xs={12} sm={6} lg={4} key={index}>
                    <div className="services_sec_inner_box">
                      <div className="services_sec_inner_box_img">
                        <h4>{item.heading}</h4>
                        <img src={item.img} alt="" />
                      </div>
                      <div className="services_sec_inner_box_info">
                        <p>{item.info}</p>
                        <Link to="/services#career-coaching">
                          <Button className="w-100" onClick="/services">
                            {item.text}
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </section>
      </section>
      <section className="why_us">
        <Container>
          <h2>Why Choose Us</h2>
          <Row className="justify-content-center">
            {datafy.map((item, index) => {
              return (
                <Col xs={12} sm={6} lg={4} key={index}>
                  <div className="why_us_inner">
                    <h4>{item.title}</h4>
                    <img src={item.img} alt="" />
                    <p>{item.text}</p>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
      <section className="community_sec">
        <Container>
          <Row>
            <Col>
              <div className="community_sec_inner">
                <h2>Niche Community of Practice (Niche CoP)</h2>
                <p>
                  Welcome to Niche CoP! The Niche Community of Practice, a
                  platform that fosters meaningful linkages with registered
                  Canadian and American nurses for long-term career support.
                  Within our community, you’ll find a diverse pool of nurses
                  with varied expertise, ready to cater to your individual needs
                  and provide unwavering support to achieve your personalized
                  career goals. Join our community today and harness the
                  collective knowledge and experience that will propel your
                  nursing journey to new heights.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <ContactUs />
    </>
  );
};

export default Homepage;
