import { Form, Formik } from "formik";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as Yup from "yup";
import unlockPath from "../../../assets/images/serv_img.webp";
import {
  CursiveLines,
  EmailIcon,
  UserIcon
} from "../../../assets/images/svgs/svgicons";
import { FORMIK_REGEX } from "../../../utils/constants";
import Button from "../../common/Button/Button";
import Input from "../../common/Formik/Input/Input";
import ContactUs from "../../ui/ContactUs/ContactUs";
import "./Services.scss";
import ServicesSection from "./ServicesSection/ServicesSection";

const Services = () => {
  const validationSchema = Yup.object({
    fname: Yup.string().required("Name is required").min(3).max(40),
    phone: Yup.string()
      .required("Phone number is required")
      .matches(FORMIK_REGEX.PHONE, "Invalid Phone Number"),
    email: Yup.string()
      .required("Email is required")
      .matches(FORMIK_REGEX.EMAIL, "Invalid Email Address"),
    message: Yup.string().required("Message is required"),
  });
  const initialValues = {
    fname: "",
    phone: "",
    email: "",
  };
  const handleSubmit = (values, submitProps) => {
    submitProps.setSubmitting(false);
  };

  return (
    <>
      <section className="service_sec">
        <Container>
          <Row>
            <Col>
              <div className="heading">
                <h2 className="text-center">
                  Guiding Nurses to Their Niche of Excellence, Where Dreams
                  Blossom and Careers Soar.
                </h2>
              </div>
              <div className="service_sec_inner"></div>
              <div className="service_sec_search">
                <h4>Are you ready to get started</h4>
                <div className="service_sec_search_inner">
                  <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                  >
                    {(formik) => {
                      return (
                        <Form>
                          <Row>
                            <Col md={3}>
                              <Input
                                name="fname"
                                label="Your Name"
                                icon={<UserIcon />}
                              />
                            </Col>
                            <Col md={3}>
                              <Input
                                label="Your Email"
                                name="email"
                                icon={<UserIcon />}
                              />
                            </Col>
                            <Col md={3}>
                              <Input
                                label="Phone Number"
                                name="phone"
                                type="number"
                                icon={<EmailIcon />}
                              />
                            </Col>
                            <Col md={3}>
                              <Button fluid className="start_btn">Get Started</Button>
                            </Col>
                          </Row>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="unlock_sec">
        <Container>
          <Row className="align-items-center">
            <Col lg={5}>
              <div className="unlock_sec_inner">
                <img src={unlockPath} alt="unlock_img" />
                <span className="unlock_lines">
                  <CursiveLines />
                </span>
                <Button>
                  Contact Us
                </Button>
              </div>
            </Col>
            <Col lg={7}>
              <div className="unlock_sec_info">
                <p>
                  Embark on a transformative journey with Niche Nursing
                  Consultancy's exceptional services. Our values of nobility,
                  integrity, collaboration, empathy, and honesty are woven into
                  every offering, ensuring you receive unparalleled support and
                  guidance. From career coaching that propels Canadian/North
                  American and internationally educated nurses forward, to our
                  NCLEX-RN/PN/RexPN preparation courses that guarantee success,
                  we stand by your side, crafting personalized study plans and
                  navigating licensing processes. Niche Nursing Consultancy
                  isn't just a service provider – we're your partners in
                  success, committed to nurturing your growth and excellence.
                  Join us and unlock a world of possibilities.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <ServicesSection />
      <ContactUs />
    </>
  );
};

export default Services;
