import { useLocation } from "react-router-dom"
import emailjs from '@emailjs/browser';
import { EMAIL_SERVICE } from "./constants";
import toast from "react-hot-toast";

export const IncludesPath = (path) => {
    let location = useLocation();
    return location.pathname.includes(path);
}

export const MatchPath = path => {
    let location = useLocation();
    return location.pathname === path;
}

export const SendMail = async (form) => {
    return new Promise((res, rej) => {
        emailjs.sendForm(EMAIL_SERVICE.SERVICE_ID, EMAIL_SERVICE.USER_TEMPLATE_ID, form, EMAIL_SERVICE.PUBLIC_KEY)
            .then((result) => {
                emailjs.sendForm(EMAIL_SERVICE.SERVICE_ID, EMAIL_SERVICE.ADMIN_TEMPLATE_ID, form, EMAIL_SERVICE.PUBLIC_KEY)
                    .then((result) => {
                        toast("🎉 Thanks 🎉");
                        res(true);
                    }, (error) => {
                        toast.error(error.text);
                    });
            }, (error) => {
                toast.error(error.text);
            });
    })
}