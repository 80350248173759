import { Form, Formik } from 'formik';
import React, { useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import * as Yup from "yup";
import { EmailIcon, PhoneIcon, UserIcon } from '../../../../assets/images/svgs/svgicons';
import { FORMIK_REGEX } from '../../../../utils/constants';
import Input from '../../../common/Formik/Input/Input';
import Textarea from '../../../common/Formik/Textarea/Textarea';
import SecondaryBtn from '../../../common/SecondaryBtn/SecondaryBtn';
import "./ContactForm.scss";
import { SendMail } from '../../../../utils/utils';

const ContactForm = () => {
    const form = useRef();
    const validationSchema = Yup.object({
        fname: Yup.string().required("Name is required").min(3).max(40),
        phone: Yup.string().matches(FORMIK_REGEX.PHONE, "Invalid Phone Number"),
        email: Yup.string().required("Email is required").matches(FORMIK_REGEX.EMAIL, "Invalid Email Address"),
        message: Yup.string().required("Message is required"),
    })
    const initialValues = {
        fname: "",
        lname: "",
        phone: "",
        message: "",
        email: "",
    }
    const handleSubmit = async (values, submitProps) => {
        await SendMail(form.current)
        submitProps.resetForm();
        submitProps.setSubmitting(false);
    }
    return (
        <div className="contact_form">
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {
                    formik => {
                        return (
                            <Form ref={form}>
                                <Row>
                                    <Col md={6}>
                                        <Input
                                            name="fname"
                                            label="First Name"
                                            icon={<UserIcon />}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input
                                            label="Last Name"
                                            name="lname"
                                            icon={<UserIcon />}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input
                                            label="Email"
                                            name="email"
                                            type="email"
                                            icon={<EmailIcon />}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input
                                            label="Phone Number"
                                            name="phone"
                                            icon={<PhoneIcon />}
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <Textarea
                                            label="Message"
                                            name="message"
                                            placeholder="Message"
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <SecondaryBtn
                                            loading={formik.isSubmitting}
                                            type="submit"
                                        >
                                            Submit
                                        </SecondaryBtn>
                                    </Col>
                                </Row>
                            </Form>
                        )
                    }
                }
            </Formik>
        </div>
    )
}

export default ContactForm;