import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import service3 from "../../../../assets/images/services/IELTS-CELBAN-Other Language exams.png";
import service2 from "../../../../assets/images/services/NCLEX-RN-PN-RexPN preparation.png";
import service4 from "../../../../assets/images/services/Resume building-Interview preparation.png";
import service5 from "../../../../assets/images/services/recruitment.png";
import careerImg from "../../../../assets/images/guidance.jpg";
import Button from "../../../common/Button/Button";
import SectionHeading from "../../../common/SectionHeading/SectionHeading";
import "./ServicesSection.scss";
import { Link } from "react-router-dom";

const ServicesSection = () => {
  return (
    <section className="services_section">
      <Container>
        <SectionHeading
          heading="Services"
          className="text-center"
          // subText="Unlock the Path to Success with Niche Nursing Consultancy's Exceptional Services!"
        />
        <h2 className="text-center">
          Unlock the Path to Success with Niche Nursing Consultancy's
          Exceptional Services!
        </h2>
        <Row id="career-coaching" className="align-items-center">
          <Col md={6}>
            <img src={careerImg} alt="" />
          </Col>
          <Col md={6}>
            <h3>Career Coaching for nurses</h3>
            <p>
              Embarking on a nursing journey? Let us be your guiding light. At
              Niche Nursing, we don't just offer career coaching – we provide a
              pathway for Canadian/North American and internationally educated
              nurses. When you're at a crossroads, our team of expert registered
              nurses will stand by your side, helping you chart a course through
              the sea of opportunities waiting for you. We understand that every
              nurse's path is unique, and our personalized support ensures you
              find the way that's right for you.
            </p>
          </Col>
        </Row>
        <Row
          id="exam-preparation"
          className="flex-md-row flex-column-reverse align-items-center"
        >
          <Col md={6}>
            <h3>NCLEX-RN/PN/RexPN preparation</h3>
            <p>
              Ready to conquer the NCLEX-RN/PN/RexPN? Our online interactive
              classes are your secret weapon. With our proven 3-month complete
              review, 6-month comprehensive review, and 1-month crash course,
              success is guaranteed. We're not just a study resource – we're
              your study partner, carving out a tailored plan to match your
              needs. From eligibility applications to licensing processes, we're
              there at every step.
            </p>

            <p>Contact us for more information!</p>
          </Col>
          <Col md={6}>
            <img src={service2} alt="" />
          </Col>
        </Row>
        <Row id="other-language-exams" className="align-items-center">
          <Col>
            <img src={service3} alt="" />
          </Col>
          <Col md={6}>
            <h3>IELTS/CELBAN/Other Language exams</h3>
            <p>
              Is language proficiency holding you back? Our direct and
              contracted services for IELTS, CELBAN, and other language exams
              are designed to propel you forward. If 1:1 language coaching is
              your preference, we have you covered.
            </p>
          </Col>
        </Row>
        <Row
          id="resume-building-interview-preparation"
          className="flex-md-row flex-column-reverse align-items-center"
        >
          <Col md={6}>
            <h3>Resume building/Interview preparation</h3>
            <p>
              We will create your fresh resume with specific cover letter,
              relevant content and professional layout to help you land your
              dream job/interview faster! We also provide 1: 1 interview
              coaching to prepare for the general and specific interview
              questions through mock interview, tips and personalized feedback!
            </p>
          </Col>
          <Col>
            <img src={service4} alt="" />
          </Col>
        </Row>
        <Row id="recruitment-support" className="align-items-center">
          <Col>
            <img src={service5} alt="" />
          </Col>
          <Col md={6}>
            <h3>Recruitment support</h3>
            <p>
              Niche Nursing supports you in your job hunt as a novice or an
              internationally educated nurse as we are partnered with relevant
              employers who are willing to offer you work opportunities! Connect
              with us to learn more!
            </p>
          </Col>
        </Row>
        <Link style={{ maxWidth: "20rem", margin: "0 auto" }} to="/contact-us">
          <Button style={{ maxWidth: "20rem" }}>Contact Us</Button>
        </Link>
      </Container>
    </section>
  );
};

export default ServicesSection;
