import { ErrorMessage, Field } from 'formik'
import React from 'react'
import ErrorMsg from '../ErrorMsg/ErrorMsg'
import "./Input.scss";

const Input = ({ label, icon, name, ...rest }) => {
    return (
        <div className="custom_input">
            <div className="input_inner">
                <span className="input_icon">{icon}</span>
                <Field
                    {...rest}
                    name={name}
                    id={name}
                />
                {
                    label &&
                    <label htmlFor={name}>{label}</label>
                }
            </div>

            {
                <ErrorMessage
                    name={name}
                    component={ErrorMsg}
                />
            }
        </div>
    )
}

export default Input