import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import "./Footer.scss";
import { EmailIcon, FacebookIcon, InstagramIcon, PhoneIcon, SocialIcon, WhatsappIcon } from '../../../assets/images/svgs/svgicons';
import { SOCIAL_LINKS } from '../../../utils/constants';
import logo from "../../../assets/images/logos/logo-white.png";

const Footer = () => {
  return (
    <footer className='footer'>
      <Container>
        <div className="footer_upper">
          <div className='footer_logo'>
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
            <p>The Niche Nursing Consultancy Inc.</p>
          </div>
          <ul>
            <li>
              <h3><PhoneIcon /> Phone</h3>
              <Link to={`tel:${SOCIAL_LINKS.PHONE}`}>{SOCIAL_LINKS.PHONE},</Link> <br/>
              <Link to={`tel:${SOCIAL_LINKS.PHONE}`}>{SOCIAL_LINKS.PHONE_SECOND}</Link>
            </li>
            <li>
              <h3><EmailIcon /> Emails</h3>
              <Link to={`mailto:${SOCIAL_LINKS.EMAIL_ID}`}>info@thenichenursing.ca</Link>
            </li>
            <li>
              <h3><SocialIcon /> Social Media</h3>
              <a className="social_link" href={SOCIAL_LINKS.INSTAGRAM}><InstagramIcon /></a>
              <a className="social_link" href={SOCIAL_LINKS.WHATSAPP}><WhatsappIcon /></a>
              <a className="social_link" href={SOCIAL_LINKS.FACEBOOK}><FacebookIcon /></a>
            </li>
          </ul>
        </div>
        <div className="footer_down">
          <p><Link to={SOCIAL_LINKS.WEBSITE}>{SOCIAL_LINKS.WEBSITE.slice(8)}</Link> © {new Date().getFullYear()} All rights reserved</p>
        </div>
      </Container>
    </footer>
  )
}

export default Footer