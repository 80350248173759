import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeLayout from "./components/layouts/HomeLayout/HomeLayout";
import { routes } from "./Routes";

const Application = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="" element={<HomeLayout />}>
                        {
                            routes.map(route => {
                                return (
                                    <Route key={route.to} path={route.to} element={route.element} />
                                )
                            })
                        }
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default Application;