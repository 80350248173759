import React from "react";
import Aboutbanner from "./AboutBanner/Aboutbanner";
import MissionVision from "./MissionVision/MissionVision";
import Values from "./Values/Values";
import Team from "./Team/Team";
import { Col, Container, Row } from "react-bootstrap";
import "./AboutUs.scss";
import SectionHeading from "../../common/SectionHeading/SectionHeading";
import { Link } from "react-router-dom";
import { SOCIAL_LINKS } from "../../../utils/constants";

const Aboutus = () => {
  return (
    <>
      <MissionVision />
      <Aboutbanner />
      <Values />
      <Team />
      <section className="community_sec_about pb-0">
        <Container>
          <Row>
            <Col>
              <div className="community_sec_about_inner">
                <SectionHeading heading=" Niche Community of Practice (Niche CoP)" />

                <p>
                  Welcome to Niche CoP! The Niche Community of Practice, a
                  platform that fosters meaningful linkages with registered
                  Canadian and American nurses for long-term career support.
                  Within our community, you’ll find a diverse pool of nurses
                  with varied expertise, ready to cater to your individual needs
                  and provide unwavering support to achieve your personalized
                  career goals. Join our community today and harness the
                  collective knowledge and experience that will propel your
                  nursing journey to new heights. <br />
                  <b>
                    Interested to join our team ? Email your resume at <Link></Link>.
                  </b>
                </p>
                <a className="email_link" href={`mailto:${SOCIAL_LINKS.EMAIL_ID}`}>{SOCIAL_LINKS.EMAIL_ID}</a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Aboutus;
