import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  EmailIcon,
  FacebookIcon,
  InstagramIcon,
  PhoneIcon,
  WhatsappIcon,
} from "../../../assets/images/svgs/svgicons";
import { SOCIAL_LINKS } from "../../../utils/constants";
import "./SocialHeader.scss";

const SocialHeader = () => {
  return (
    <header className="social_header">
      <Container>
        <div className="social_inner">
          <div className="social_left">
            <ul>
              <li>
                <Link to={`tel:${SOCIAL_LINKS.PHONE}`}>
                  <PhoneIcon />
                  {SOCIAL_LINKS.PHONE}
                </Link>
              </li>
              <li>
                <Link to={`mailto:${SOCIAL_LINKS.EMAIL_ID}`}>
                  <EmailIcon />
                  info@thenichenursing.ca
                </Link>
              </li>
            </ul>
          </div>
          <div className="social_right">
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={SOCIAL_LINKS.INSTAGRAM}
                >
                  <InstagramIcon />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={SOCIAL_LINKS.FACEBOOK}
                >
                  <FacebookIcon />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={SOCIAL_LINKS.WHATSAPP}
                >
                  <WhatsappIcon />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default SocialHeader;
