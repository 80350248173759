import { Toaster } from "react-hot-toast";
import Application from "./Application";

function App() {
  return (
    <>
      <Toaster />
      <Application />
    </>
  );
}

export default App;
