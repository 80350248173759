import React from 'react';
import "./Toggler.scss";

const Toggler = ({ active, handleActive }) => {
    return (
        <button onClick={handleActive} className={`d-sm-none toggler ${active ? "active" : ""}`}>
            <span></span>
            <span></span>
            <span></span>
        </button>
    )
}

export default Toggler