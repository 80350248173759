import Aboutus from "./components/Pages/AboutUs/AboutUs";
import ContactUs from "./components/Pages/ContactUs/ContactUs";
import Homepage from "./components/Pages/Homepage/Homepage";
import Services from "./components/Pages/Services/Services"
export const routes = [
    {
        to: "",
        element: <Homepage />
    },
    {
        to: "about-us",
        element: <Aboutus />
    },
    {
        to: "services",
        element: <Services />,
    },
    {
        to: "contact-us",
        element: <ContactUs />
    }
]