export const CrossIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" id="times"><path fill="#003E46" d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" /></svg>
)

export const CloseEye = () => (
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" width={24} height={24} viewBox="0 0 24 24" id="eye-slash"><path fill="#003E46" d="M10.94,6.08A6.93,6.93,0,0,1,12,6c3.18,0,6.17,2.29,7.91,6a15.23,15.23,0,0,1-.9,1.64,1,1,0,0,0-.16.55,1,1,0,0,0,1.86.5,15.77,15.77,0,0,0,1.21-2.3,1,1,0,0,0,0-.79C19.9,6.91,16.1,4,12,4a7.77,7.77,0,0,0-1.4.12,1,1,0,1,0,.34,2ZM3.71,2.29A1,1,0,0,0,2.29,3.71L5.39,6.8a14.62,14.62,0,0,0-3.31,4.8,1,1,0,0,0,0,.8C4.1,17.09,7.9,20,12,20a9.26,9.26,0,0,0,5.05-1.54l3.24,3.25a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Zm6.36,9.19,2.45,2.45A1.81,1.81,0,0,1,12,14a2,2,0,0,1-2-2A1.81,1.81,0,0,1,10.07,11.48ZM12,18c-3.18,0-6.17-2.29-7.9-6A12.09,12.09,0,0,1,6.8,8.21L8.57,10A4,4,0,0,0,14,15.43L15.59,17A7.24,7.24,0,0,1,12,18Z" /></svg>
)

export const OpenEye = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" id="eye"><path fill="#003E46" d="M21.92,11.6C19.9,6.91,16.1,4,12,4S4.1,6.91,2.08,11.6a1,1,0,0,0,0,.8C4.1,17.09,7.9,20,12,20s7.9-2.91,9.92-7.6A1,1,0,0,0,21.92,11.6ZM12,18c-3.17,0-6.17-2.29-7.9-6C5.83,8.29,8.83,6,12,6s6.17,2.29,7.9,6C18.17,15.71,15.17,18,12,18ZM12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z" /></svg>
)

export const UserIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} viewBox="0 0 24 24" id="user"><path fill="#003E46" d="M15.71,12.71a6,6,0,1,0-7.42,0,10,10,0,0,0-6.22,8.18,1,1,0,0,0,2,.22,8,8,0,0,1,15.9,0,1,1,0,0,0,1,.89h.11a1,1,0,0,0,.88-1.1A10,10,0,0,0,15.71,12.71ZM12,12a4,4,0,1,1,4-4A4,4,0,0,1,12,12Z" /></svg>
)

export const EmailIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} viewBox="0 0 24 24" id="envelope-alt"><path fill="#003E46" d="M19,4H5A3,3,0,0,0,2,7V17a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V7A3,3,0,0,0,19,4ZM5,6H19a1,1,0,0,1,1,1l-8,4.88L4,7A1,1,0,0,1,5,6ZM20,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V9.28l7.48,4.57a1,1,0,0,0,1,0L20,9.28Z" /></svg>
)
export const NavigateIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} data-name="Layer 1" viewBox="0 0 24 24" id="navigator"><path fill="currentcolor" d="M20.17,9.23l-14-5.78a3,3,0,0,0-4,3.7L3.71,12,2.13,16.85A3,3,0,0,0,2.94,20a3,3,0,0,0,2,.8,3,3,0,0,0,1.15-.23l14.05-5.78a3,3,0,0,0,0-5.54ZM5.36,18.7a1,1,0,0,1-1.06-.19,1,1,0,0,1-.27-1L5.49,13H19.22ZM5.49,11,4,6.53a1,1,0,0,1,.27-1A1,1,0,0,1,5,5.22a1,1,0,0,1,.39.08L19.22,11Z" /></svg>
)
export const FacebookIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} data-name="Layer 1" viewBox="0 0 24 24" id="facebook-f"><path fill="transparent" d="M15.12,5.32H17V2.14A26.11,26.11,0,0,0,14.26,2C11.54,2,9.68,3.66,9.68,6.7V9.32H6.61v3.56H9.68V22h3.68V12.88h3.06l.46-3.56H13.36V7.05C13.36,6,13.64,5.32,15.12,5.32Z" /></svg>
)

export const InstagramIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" id="instagram"><path fill="#1B4545" d="M17.34,5.46h0a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,17.34,5.46Zm4.6,2.42a7.59,7.59,0,0,0-.46-2.43,4.94,4.94,0,0,0-1.16-1.77,4.7,4.7,0,0,0-1.77-1.15,7.3,7.3,0,0,0-2.43-.47C15.06,2,14.72,2,12,2s-3.06,0-4.12.06a7.3,7.3,0,0,0-2.43.47A4.78,4.78,0,0,0,3.68,3.68,4.7,4.7,0,0,0,2.53,5.45a7.3,7.3,0,0,0-.47,2.43C2,8.94,2,9.28,2,12s0,3.06.06,4.12a7.3,7.3,0,0,0,.47,2.43,4.7,4.7,0,0,0,1.15,1.77,4.78,4.78,0,0,0,1.77,1.15,7.3,7.3,0,0,0,2.43.47C8.94,22,9.28,22,12,22s3.06,0,4.12-.06a7.3,7.3,0,0,0,2.43-.47,4.7,4.7,0,0,0,1.77-1.15,4.85,4.85,0,0,0,1.16-1.77,7.59,7.59,0,0,0,.46-2.43c0-1.06.06-1.4.06-4.12S22,8.94,21.94,7.88ZM20.14,16a5.61,5.61,0,0,1-.34,1.86,3.06,3.06,0,0,1-.75,1.15,3.19,3.19,0,0,1-1.15.75,5.61,5.61,0,0,1-1.86.34c-1,.05-1.37.06-4,.06s-3,0-4-.06A5.73,5.73,0,0,1,6.1,19.8,3.27,3.27,0,0,1,5,19.05a3,3,0,0,1-.74-1.15A5.54,5.54,0,0,1,3.86,16c0-1-.06-1.37-.06-4s0-3,.06-4A5.54,5.54,0,0,1,4.21,6.1,3,3,0,0,1,5,5,3.14,3.14,0,0,1,6.1,4.2,5.73,5.73,0,0,1,8,3.86c1,0,1.37-.06,4-.06s3,0,4,.06a5.61,5.61,0,0,1,1.86.34A3.06,3.06,0,0,1,19.05,5,3.06,3.06,0,0,1,19.8,6.1,5.61,5.61,0,0,1,20.14,8c.05,1,.06,1.37.06,4S20.19,15,20.14,16ZM12,6.87A5.13,5.13,0,1,0,17.14,12,5.12,5.12,0,0,0,12,6.87Zm0,8.46A3.33,3.33,0,1,1,15.33,12,3.33,3.33,0,0,1,12,15.33Z"></path></svg>
)

export const YoutubeIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} data-name="Layer 1" viewBox="0 0 24 24" id="youtube"><path fill="transparent" d="M23,9.71a8.5,8.5,0,0,0-.91-4.13,2.92,2.92,0,0,0-1.72-1A78.36,78.36,0,0,0,12,4.27a78.45,78.45,0,0,0-8.34.3,2.87,2.87,0,0,0-1.46.74c-.9.83-1,2.25-1.1,3.45a48.29,48.29,0,0,0,0,6.48,9.55,9.55,0,0,0,.3,2,3.14,3.14,0,0,0,.71,1.36,2.86,2.86,0,0,0,1.49.78,45.18,45.18,0,0,0,6.5.33c3.5.05,6.57,0,10.2-.28a2.88,2.88,0,0,0,1.53-.78,2.49,2.49,0,0,0,.61-1,10.58,10.58,0,0,0,.52-3.4C23,13.69,23,10.31,23,9.71ZM9.74,14.85V8.66l5.92,3.11C14,12.69,11.81,13.73,9.74,14.85Z" /></svg>
)

export const TwitterIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} data-name="Layer 1" viewBox="0 0 24 24" id="twitter"><path fill="transparent" d="M22,5.8a8.49,8.49,0,0,1-2.36.64,4.13,4.13,0,0,0,1.81-2.27,8.21,8.21,0,0,1-2.61,1,4.1,4.1,0,0,0-7,3.74A11.64,11.64,0,0,1,3.39,4.62a4.16,4.16,0,0,0-.55,2.07A4.09,4.09,0,0,0,4.66,10.1,4.05,4.05,0,0,1,2.8,9.59v.05a4.1,4.1,0,0,0,3.3,4A3.93,3.93,0,0,1,5,13.81a4.9,4.9,0,0,1-.77-.07,4.11,4.11,0,0,0,3.83,2.84A8.22,8.22,0,0,1,3,18.34a7.93,7.93,0,0,1-1-.06,11.57,11.57,0,0,0,6.29,1.85A11.59,11.59,0,0,0,20,8.45c0-.17,0-.35,0-.53A8.43,8.43,0,0,0,22,5.8Z" /></svg>
)

export const NurseIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" id="user-md" width={24} height={24}><path fill="#1B4545" d="m15.899 13.229-.005-.002c-.063-.027-.124-.058-.188-.083A5.988 5.988 0 0 0 18 8.434a5.29 5.29 0 0 0-.045-.63.946.946 0 0 0 .038-.122l.281-2.397a3.006 3.006 0 0 0-2.442-3.302l-.79-.143a16.931 16.931 0 0 0-6.083 0l-.791.143a3.006 3.006 0 0 0-2.442 3.302l.28 2.397a.946.946 0 0 0 .039.122 5.29 5.29 0 0 0-.045.63 5.988 5.988 0 0 0 2.294 4.71c-.064.025-.125.056-.188.083l-.005.002a9.948 9.948 0 0 0-6.035 8.097 1 1 0 0 0 1.988.217 7.948 7.948 0 0 1 4.216-6.185l3.023 3.023a1 1 0 0 0 1.414 0l3.023-3.023a7.948 7.948 0 0 1 4.216 6.185 1 1 0 0 0 .992.892 1.048 1.048 0 0 0 .11-.006 1 1 0 0 0 .886-1.103 9.948 9.948 0 0 0-6.036-8.097ZM7.712 5.051a1.002 1.002 0 0 1 .814-1.1l.79-.143a14.93 14.93 0 0 1 5.368 0l.79.143a1.002 1.002 0 0 1 .814 1.1l-.178 1.514H7.89ZM12 16.261l-1.65-1.651a7.85 7.85 0 0 1 3.3 0Zm0-3.826a4.005 4.005 0 0 1-3.998-3.87h7.996A4.005 4.005 0 0 1 12 12.435Z"></path></svg>
)

export const PhoneIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" id="phone"><path fill="#1B4545" d="M19.44,13c-.22,0-.45-.07-.67-.12a9.44,9.44,0,0,1-1.31-.39,2,2,0,0,0-2.48,1l-.22.45a12.18,12.18,0,0,1-2.66-2,12.18,12.18,0,0,1-2-2.66L10.52,9a2,2,0,0,0,1-2.48,10.33,10.33,0,0,1-.39-1.31c-.05-.22-.09-.45-.12-.68a3,3,0,0,0-3-2.49h-3a3,3,0,0,0-3,3.41A19,19,0,0,0,18.53,21.91l.38,0a3,3,0,0,0,2-.76,3,3,0,0,0,1-2.25v-3A3,3,0,0,0,19.44,13Zm.5,6a1,1,0,0,1-.34.75,1.05,1.05,0,0,1-.82.25A17,17,0,0,1,4.07,5.22a1.09,1.09,0,0,1,.25-.82,1,1,0,0,1,.75-.34h3a1,1,0,0,1,1,.79q.06.41.15.81a11.12,11.12,0,0,0,.46,1.55l-1.4.65a1,1,0,0,0-.49,1.33,14.49,14.49,0,0,0,7,7,1,1,0,0,0,.76,0,1,1,0,0,0,.57-.52l.62-1.4a13.69,13.69,0,0,0,1.58.46q.4.09.81.15a1,1,0,0,1,.79,1Z" /></svg>
)

export const LeftArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" width="24" height={24} viewBox="0 0 24 24" id="arrow-left"><path fill="#1B4545" d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z" /></svg>
)

export const WhatsappIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" id="whatsapp"><path fill="#1B4545" d="M16.6 14c-.2-.1-1.5-.7-1.7-.8-.2-.1-.4-.1-.6.1-.2.2-.6.8-.8 1-.1.2-.3.2-.5.1-.7-.3-1.4-.7-2-1.2-.5-.5-1-1.1-1.4-1.7-.1-.2 0-.4.1-.5.1-.1.2-.3.4-.4.1-.1.2-.3.2-.4.1-.1.1-.3 0-.4-.1-.1-.6-1.3-.8-1.8-.1-.7-.3-.7-.5-.7h-.5c-.2 0-.5.2-.6.3-.6.6-.9 1.3-.9 2.1.1.9.4 1.8 1 2.6 1.1 1.6 2.5 2.9 4.2 3.7.5.2.9.4 1.4.5.5.2 1 .2 1.6.1.7-.1 1.3-.6 1.7-1.2.2-.4.2-.8.1-1.2l-.4-.2m2.5-9.1C15.2 1 8.9 1 5 4.9c-3.2 3.2-3.8 8.1-1.6 12L2 22l5.3-1.4c1.5.8 3.1 1.2 4.7 1.2 5.5 0 9.9-4.4 9.9-9.9.1-2.6-1-5.1-2.8-7m-2.7 14c-1.3.8-2.8 1.3-4.4 1.3-1.5 0-2.9-.4-4.2-1.1l-.3-.2-3.1.8.8-3-.2-.3c-2.4-4-1.2-9 2.7-11.5S16.6 3.7 19 7.5c2.4 3.9 1.3 9-2.6 11.4"></path></svg>
)

export const LoadingIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" id="spinner-alt"><path fill="#6563FF" d="M6.804 15a1 1 0 0 0-1.366-.366l-1.732 1a1 1 0 0 0 1 1.732l1.732-1A1 1 0 0 0 6.804 15ZM3.706 8.366l1.732 1a1 1 0 1 0 1-1.732l-1.732-1a1 1 0 0 0-1 1.732ZM6 12a1 1 0 0 0-1-1H3a1 1 0 0 0 0 2h2a1 1 0 0 0 1-1Zm11.196-3a1 1 0 0 0 1.366.366l1.732-1a1 1 0 1 0-1-1.732l-1.732 1A1 1 0 0 0 17.196 9ZM15 6.804a1 1 0 0 0 1.366-.366l1-1.732a1 1 0 1 0-1.732-1l-1 1.732A1 1 0 0 0 15 6.804Zm5.294 8.83-1.732-1a1 1 0 1 0-1 1.732l1.732 1a1 1 0 0 0 1-1.732Zm-3.928 1.928a1 1 0 1 0-1.732 1l1 1.732a1 1 0 1 0 1.732-1ZM21 11h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2Zm-9 7a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1Zm-3-.804a1 1 0 0 0-1.366.366l-1 1.732a1 1 0 0 0 1.732 1l1-1.732A1 1 0 0 0 9 17.196ZM12 2a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0V3a1 1 0 0 0-1-1Z"></path></svg>
)

export const SocialIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="globe"><path fill="#6563FF" d="M21.41,8.64s0,0,0-.05a10,10,0,0,0-18.78,0s0,0,0,.05a9.86,9.86,0,0,0,0,6.72s0,0,0,.05a10,10,0,0,0,18.78,0s0,0,0-.05a9.86,9.86,0,0,0,0-6.72ZM4.26,14a7.82,7.82,0,0,1,0-4H6.12a16.73,16.73,0,0,0,0,4Zm.82,2h1.4a12.15,12.15,0,0,0,1,2.57A8,8,0,0,1,5.08,16Zm1.4-8H5.08A8,8,0,0,1,7.45,5.43,12.15,12.15,0,0,0,6.48,8ZM11,19.7A6.34,6.34,0,0,1,8.57,16H11ZM11,14H8.14a14.36,14.36,0,0,1,0-4H11Zm0-6H8.57A6.34,6.34,0,0,1,11,4.3Zm7.92,0h-1.4a12.15,12.15,0,0,0-1-2.57A8,8,0,0,1,18.92,8ZM13,4.3A6.34,6.34,0,0,1,15.43,8H13Zm0,15.4V16h2.43A6.34,6.34,0,0,1,13,19.7ZM15.86,14H13V10h2.86a14.36,14.36,0,0,1,0,4Zm.69,4.57a12.15,12.15,0,0,0,1-2.57h1.4A8,8,0,0,1,16.55,18.57ZM19.74,14H17.88A16.16,16.16,0,0,0,18,12a16.28,16.28,0,0,0-.12-2h1.86a7.82,7.82,0,0,1,0,4Z"></path></svg>
)

export const Dots = () => (
    <svg width="330" height="402" viewBox="0 0 330 402" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="7.30088" cy="8.03097" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="7.30088" cy="47.4558" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="46.7257" cy="8.03097" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="46.7257" cy="47.4558" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="86.1505" cy="8.03097" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="86.1505" cy="47.4558" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="125.575" cy="8.03097" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="125.575" cy="47.4558" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="165" cy="8.03097" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="165" cy="47.4558" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="202.964" cy="8.03097" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="202.964" cy="47.4558" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="242.389" cy="8.03097" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="242.389" cy="47.4558" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="281.814" cy="8.03097" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="281.814" cy="47.4558" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="321.239" cy="8.03097" rx="7.30088" ry="8.03097" fill="#003E46" />
        <circle cx="321.969" cy="47.4558" r="8.03097" fill="#003E46" />
        <ellipse cx="7.30088" cy="86.8806" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="7.30088" cy="126.305" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="46.7257" cy="86.8806" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="46.7257" cy="126.305" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="86.1505" cy="86.8806" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="86.1505" cy="126.305" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="125.575" cy="86.8806" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="125.575" cy="126.305" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="165" cy="86.8806" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="165" cy="126.305" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="202.964" cy="86.8806" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="202.964" cy="126.305" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="242.389" cy="86.8806" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="242.389" cy="126.305" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="281.814" cy="86.8806" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="281.814" cy="126.305" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="321.239" cy="86.8806" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="321.239" cy="126.305" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="7.30088" cy="165.73" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="7.30088" cy="203.695" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="46.7257" cy="165.73" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="46.7257" cy="203.695" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="86.1505" cy="165.73" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="86.1505" cy="203.695" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="125.575" cy="165.73" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="125.575" cy="203.695" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="165" cy="165.73" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="165" cy="203.695" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="202.964" cy="165.73" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="202.964" cy="203.695" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="242.389" cy="165.73" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="242.389" cy="203.695" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="281.814" cy="165.73" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="281.814" cy="203.695" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="321.239" cy="165.73" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="321.239" cy="203.695" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="7.30088" cy="235.819" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="7.30088" cy="275.243" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="46.7257" cy="235.819" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="46.7257" cy="275.243" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="86.1505" cy="235.819" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="86.1505" cy="275.243" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="125.575" cy="235.819" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="125.575" cy="275.243" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="165" cy="235.819" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="165" cy="275.243" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="202.964" cy="235.819" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="202.964" cy="275.243" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="242.389" cy="235.819" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="242.389" cy="275.243" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="281.814" cy="235.819" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="281.814" cy="275.243" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="321.239" cy="235.819" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="321.239" cy="275.243" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="7.30088" cy="314.668" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="7.30088" cy="354.093" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="46.7257" cy="314.668" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="46.7257" cy="354.093" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="86.1505" cy="314.668" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="86.1505" cy="354.093" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="125.575" cy="314.668" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="125.575" cy="354.093" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="165" cy="314.668" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="165" cy="354.093" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="202.964" cy="314.668" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="202.964" cy="354.093" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="242.389" cy="314.668" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="242.389" cy="354.093" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="281.814" cy="314.668" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="281.814" cy="354.093" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="321.239" cy="314.668" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="321.239" cy="354.093" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="7.30088" cy="393.518" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="46.7257" cy="393.518" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="86.1505" cy="393.518" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="125.575" cy="393.518" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="165" cy="393.518" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="202.964" cy="393.518" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="242.389" cy="393.518" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="281.814" cy="393.518" rx="7.30088" ry="8.03097" fill="#003E46" />
        <ellipse cx="321.239" cy="393.518" rx="7.30088" ry="8.03097" fill="#003E46" />
    </svg>

)

export const CursiveLinesVertical = () => (
    <svg width="243" height="461" viewBox="0 0 243 461" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M229.847 2C238.263 6.0724 250.046 17.5378 229.847 30.82C209.647 44.1023 221.43 55.15 229.847 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 59.0122C238.263 63.0846 250.046 74.55 229.847 87.8322C209.647 101.115 221.43 112.162 229.847 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 116.027C238.263 120.1 250.046 131.565 229.847 144.847C209.647 158.13 221.43 169.177 229.847 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 173.041C238.263 177.113 250.046 188.579 229.847 201.861C209.647 215.143 221.43 226.191 229.847 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 230.054C238.263 234.19 250.046 245.835 229.847 259.325C209.647 272.815 221.43 284.035 229.847 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 287.958C238.263 292.031 250.046 303.496 229.847 316.779C209.647 330.061 221.43 341.108 229.847 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 344.973C238.263 349.045 250.046 360.51 229.847 373.793C209.647 387.075 221.43 398.123 229.847 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 401.986C238.263 406.059 250.046 417.524 229.847 430.806C209.647 444.089 221.43 455.136 229.847 459" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 2C207.459 6.0724 219.242 17.5378 199.042 30.82C178.842 44.1023 190.625 55.15 199.042 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 59.012C207.459 63.0844 219.242 74.5497 199.042 87.832C178.842 101.114 190.625 112.162 199.042 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 116.027C207.459 120.1 219.242 131.565 199.042 144.847C178.842 158.13 190.625 169.177 199.042 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 173.041C207.459 177.113 219.242 188.579 199.042 201.861C178.842 215.143 190.625 226.191 199.042 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 230.054C207.459 234.19 219.242 245.835 199.042 259.325C178.842 272.815 190.625 284.035 199.042 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 287.958C207.459 292.031 219.242 303.496 199.042 316.778C178.842 330.061 190.625 341.108 199.042 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 344.973C207.459 349.045 219.242 360.51 199.042 373.793C178.842 387.075 190.625 398.123 199.042 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 401.986C207.459 406.059 219.242 417.524 199.042 430.806C178.842 444.089 190.625 455.136 199.042 459" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 2C176.654 6.0724 188.438 17.5378 168.238 30.82C148.038 44.1023 159.821 55.15 168.238 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 59.012C176.654 63.0844 188.438 74.5497 168.238 87.832C148.038 101.114 159.821 112.162 168.238 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 116.027C176.654 120.1 188.438 131.565 168.238 144.847C148.038 158.13 159.821 169.177 168.238 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 173.041C176.654 177.113 188.438 188.579 168.238 201.861C148.038 215.143 159.821 226.191 168.238 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 230.054C176.654 234.19 188.438 245.835 168.238 259.325C148.038 272.815 159.821 284.035 168.238 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 287.958C176.654 292.031 188.438 303.496 168.238 316.778C148.038 330.061 159.821 341.108 168.238 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 344.973C176.654 349.045 188.438 360.51 168.238 373.793C148.038 387.075 159.821 398.123 168.238 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 401.986C176.654 406.059 188.438 417.524 168.238 430.806C148.038 444.089 159.821 455.136 168.238 459" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 2C145.85 6.0724 157.633 17.5378 137.433 30.82C117.234 44.1023 129.017 55.15 137.433 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 59.012C145.85 63.0844 157.633 74.5497 137.433 87.832C117.234 101.114 129.017 112.162 137.433 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 116.027C145.85 120.1 157.633 131.565 137.433 144.847C117.234 158.13 129.017 169.177 137.433 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 173.041C145.85 177.113 157.633 188.579 137.433 201.861C117.234 215.143 129.017 226.191 137.433 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 230.054C145.85 234.19 157.633 245.835 137.433 259.325C117.234 272.815 129.017 284.035 137.433 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 287.958C145.85 292.031 157.633 303.496 137.433 316.778C117.234 330.061 129.017 341.108 137.433 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 344.973C145.85 349.045 157.633 360.51 137.433 373.793C117.234 387.075 129.017 398.123 137.433 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 401.986C145.85 406.059 157.633 417.524 137.433 430.806C117.234 444.089 129.017 455.136 137.433 459" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 2C238.263 6.0724 250.046 17.5378 229.847 30.82C209.647 44.1023 221.43 55.15 229.847 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 59.0122C238.263 63.0846 250.046 74.55 229.847 87.8322C209.647 101.115 221.43 112.162 229.847 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 116.027C238.263 120.1 250.046 131.565 229.847 144.847C209.647 158.13 221.43 169.177 229.847 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 173.041C238.263 177.113 250.046 188.579 229.847 201.861C209.647 215.143 221.43 226.191 229.847 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 230.054C238.263 234.19 250.046 245.835 229.847 259.325C209.647 272.815 221.43 284.035 229.847 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 287.958C238.263 292.031 250.046 303.496 229.847 316.779C209.647 330.061 221.43 341.108 229.847 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 344.973C238.263 349.045 250.046 360.51 229.847 373.793C209.647 387.075 221.43 398.123 229.847 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 401.986C238.263 406.059 250.046 417.524 229.847 430.806C209.647 444.089 221.43 455.136 229.847 459" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 2C207.459 6.0724 219.242 17.5378 199.042 30.82C178.842 44.1023 190.625 55.15 199.042 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 59.012C207.459 63.0844 219.242 74.5497 199.042 87.832C178.842 101.114 190.625 112.162 199.042 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 116.027C207.459 120.1 219.242 131.565 199.042 144.847C178.842 158.13 190.625 169.177 199.042 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 173.041C207.459 177.113 219.242 188.579 199.042 201.861C178.842 215.143 190.625 226.191 199.042 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 230.054C207.459 234.19 219.242 245.835 199.042 259.325C178.842 272.815 190.625 284.035 199.042 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 287.958C207.459 292.031 219.242 303.496 199.042 316.778C178.842 330.061 190.625 341.108 199.042 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 344.973C207.459 349.045 219.242 360.51 199.042 373.793C178.842 387.075 190.625 398.123 199.042 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 401.986C207.459 406.059 219.242 417.524 199.042 430.806C178.842 444.089 190.625 455.136 199.042 459" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 2C176.654 6.0724 188.438 17.5378 168.238 30.82C148.038 44.1023 159.821 55.15 168.238 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 59.012C176.654 63.0844 188.438 74.5497 168.238 87.832C148.038 101.114 159.821 112.162 168.238 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 116.027C176.654 120.1 188.438 131.565 168.238 144.847C148.038 158.13 159.821 169.177 168.238 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 173.041C176.654 177.113 188.438 188.579 168.238 201.861C148.038 215.143 159.821 226.191 168.238 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 230.054C176.654 234.19 188.438 245.835 168.238 259.325C148.038 272.815 159.821 284.035 168.238 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 287.958C176.654 292.031 188.438 303.496 168.238 316.778C148.038 330.061 159.821 341.108 168.238 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 344.973C176.654 349.045 188.438 360.51 168.238 373.793C148.038 387.075 159.821 398.123 168.238 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 401.986C176.654 406.059 188.438 417.524 168.238 430.806C148.038 444.089 159.821 455.136 168.238 459" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 2C145.85 6.0724 157.633 17.5378 137.433 30.82C117.234 44.1023 129.017 55.15 137.433 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 59.012C145.85 63.0844 157.633 74.5497 137.433 87.832C117.234 101.114 129.017 112.162 137.433 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 116.027C145.85 120.1 157.633 131.565 137.433 144.847C117.234 158.13 129.017 169.177 137.433 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 173.041C145.85 177.113 157.633 188.579 137.433 201.861C117.234 215.143 129.017 226.191 137.433 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 230.054C145.85 234.19 157.633 245.835 137.433 259.325C117.234 272.815 129.017 284.035 137.433 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 287.958C145.85 292.031 157.633 303.496 137.433 316.778C117.234 330.061 129.017 341.108 137.433 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 344.973C145.85 349.045 157.633 360.51 137.433 373.793C117.234 387.075 129.017 398.123 137.433 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 401.986C145.85 406.059 157.633 417.524 137.433 430.806C117.234 444.089 129.017 455.136 137.433 459" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 2C238.263 6.0724 250.046 17.5378 229.847 30.82C209.647 44.1023 221.43 55.15 229.847 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 59.0122C238.263 63.0846 250.046 74.55 229.847 87.8322C209.647 101.115 221.43 112.162 229.847 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 116.027C238.263 120.1 250.046 131.565 229.847 144.847C209.647 158.13 221.43 169.177 229.847 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 173.041C238.263 177.113 250.046 188.579 229.847 201.861C209.647 215.143 221.43 226.191 229.847 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 230.054C238.263 234.19 250.046 245.835 229.847 259.325C209.647 272.815 221.43 284.035 229.847 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 287.958C238.263 292.031 250.046 303.496 229.847 316.779C209.647 330.061 221.43 341.108 229.847 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 344.973C238.263 349.045 250.046 360.51 229.847 373.793C209.647 387.075 221.43 398.123 229.847 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 401.986C238.263 406.059 250.046 417.524 229.847 430.806C209.647 444.089 221.43 455.136 229.847 459" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 2C207.459 6.0724 219.242 17.5378 199.042 30.82C178.842 44.1023 190.625 55.15 199.042 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 59.012C207.459 63.0844 219.242 74.5497 199.042 87.832C178.842 101.114 190.625 112.162 199.042 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 116.027C207.459 120.1 219.242 131.565 199.042 144.847C178.842 158.13 190.625 169.177 199.042 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 173.041C207.459 177.113 219.242 188.579 199.042 201.861C178.842 215.143 190.625 226.191 199.042 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 230.054C207.459 234.19 219.242 245.835 199.042 259.325C178.842 272.815 190.625 284.035 199.042 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 287.958C207.459 292.031 219.242 303.496 199.042 316.778C178.842 330.061 190.625 341.108 199.042 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 344.973C207.459 349.045 219.242 360.51 199.042 373.793C178.842 387.075 190.625 398.123 199.042 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 401.986C207.459 406.059 219.242 417.524 199.042 430.806C178.842 444.089 190.625 455.136 199.042 459" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 2C176.654 6.0724 188.438 17.5378 168.238 30.82C148.038 44.1023 159.821 55.15 168.238 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 59.012C176.654 63.0844 188.438 74.5497 168.238 87.832C148.038 101.114 159.821 112.162 168.238 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 116.027C176.654 120.1 188.438 131.565 168.238 144.847C148.038 158.13 159.821 169.177 168.238 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 173.041C176.654 177.113 188.438 188.579 168.238 201.861C148.038 215.143 159.821 226.191 168.238 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 230.054C176.654 234.19 188.438 245.835 168.238 259.325C148.038 272.815 159.821 284.035 168.238 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 287.958C176.654 292.031 188.438 303.496 168.238 316.778C148.038 330.061 159.821 341.108 168.238 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 344.973C176.654 349.045 188.438 360.51 168.238 373.793C148.038 387.075 159.821 398.123 168.238 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 401.986C176.654 406.059 188.438 417.524 168.238 430.806C148.038 444.089 159.821 455.136 168.238 459" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 2C145.85 6.0724 157.633 17.5378 137.433 30.82C117.234 44.1023 129.017 55.15 137.433 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 59.012C145.85 63.0844 157.633 74.5497 137.433 87.832C117.234 101.114 129.017 112.162 137.433 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 116.027C145.85 120.1 157.633 131.565 137.433 144.847C117.234 158.13 129.017 169.177 137.433 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 173.041C145.85 177.113 157.633 188.579 137.433 201.861C117.234 215.143 129.017 226.191 137.433 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 230.054C145.85 234.19 157.633 245.835 137.433 259.325C117.234 272.815 129.017 284.035 137.433 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 287.958C145.85 292.031 157.633 303.496 137.433 316.778C117.234 330.061 129.017 341.108 137.433 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 344.973C145.85 349.045 157.633 360.51 137.433 373.793C117.234 387.075 129.017 398.123 137.433 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 401.986C145.85 406.059 157.633 417.524 137.433 430.806C117.234 444.089 129.017 455.136 137.433 459" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 2C238.263 6.0724 250.046 17.5378 229.847 30.82C209.647 44.1023 221.43 55.15 229.847 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 59.0122C238.263 63.0846 250.046 74.55 229.847 87.8322C209.647 101.115 221.43 112.162 229.847 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 116.027C238.263 120.1 250.046 131.565 229.847 144.847C209.647 158.13 221.43 169.177 229.847 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 173.041C238.263 177.113 250.046 188.579 229.847 201.861C209.647 215.143 221.43 226.191 229.847 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 230.054C238.263 234.19 250.046 245.835 229.847 259.325C209.647 272.815 221.43 284.035 229.847 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 287.958C238.263 292.031 250.046 303.496 229.847 316.779C209.647 330.061 221.43 341.108 229.847 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 344.973C238.263 349.045 250.046 360.51 229.847 373.793C209.647 387.075 221.43 398.123 229.847 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M229.847 401.986C238.263 406.059 250.046 417.524 229.847 430.806C209.647 444.089 221.43 455.136 229.847 459" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 2C207.459 6.0724 219.242 17.5378 199.042 30.82C178.842 44.1023 190.625 55.15 199.042 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 59.012C207.459 63.0844 219.242 74.5497 199.042 87.832C178.842 101.114 190.625 112.162 199.042 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 116.027C207.459 120.1 219.242 131.565 199.042 144.847C178.842 158.13 190.625 169.177 199.042 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 173.041C207.459 177.113 219.242 188.579 199.042 201.861C178.842 215.143 190.625 226.191 199.042 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 230.054C207.459 234.19 219.242 245.835 199.042 259.325C178.842 272.815 190.625 284.035 199.042 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 287.958C207.459 292.031 219.242 303.496 199.042 316.778C178.842 330.061 190.625 341.108 199.042 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 344.973C207.459 349.045 219.242 360.51 199.042 373.793C178.842 387.075 190.625 398.123 199.042 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M199.042 401.986C207.459 406.059 219.242 417.524 199.042 430.806C178.842 444.089 190.625 455.136 199.042 459" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 2C176.654 6.0724 188.438 17.5378 168.238 30.82C148.038 44.1023 159.821 55.15 168.238 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 59.012C176.654 63.0844 188.438 74.5497 168.238 87.832C148.038 101.114 159.821 112.162 168.238 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 116.027C176.654 120.1 188.438 131.565 168.238 144.847C148.038 158.13 159.821 169.177 168.238 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 173.041C176.654 177.113 188.438 188.579 168.238 201.861C148.038 215.143 159.821 226.191 168.238 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 230.054C176.654 234.19 188.438 245.835 168.238 259.325C148.038 272.815 159.821 284.035 168.238 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 287.958C176.654 292.031 188.438 303.496 168.238 316.778C148.038 330.061 159.821 341.108 168.238 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 344.973C176.654 349.045 188.438 360.51 168.238 373.793C148.038 387.075 159.821 398.123 168.238 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M168.238 401.986C176.654 406.059 188.438 417.524 168.238 430.806C148.038 444.089 159.821 455.136 168.238 459" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 2C145.85 6.0724 157.633 17.5378 137.433 30.82C117.234 44.1023 129.017 55.15 137.433 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 59.012C145.85 63.0844 157.633 74.5497 137.433 87.832C117.234 101.114 129.017 112.162 137.433 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 116.027C145.85 120.1 157.633 131.565 137.433 144.847C117.234 158.13 129.017 169.177 137.433 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 173.041C145.85 177.113 157.633 188.579 137.433 201.861C117.234 215.143 129.017 226.191 137.433 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 230.054C145.85 234.19 157.633 245.835 137.433 259.325C117.234 272.815 129.017 284.035 137.433 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 287.958C145.85 292.031 157.633 303.496 137.433 316.778C117.234 330.061 129.017 341.108 137.433 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 344.973C145.85 349.045 157.633 360.51 137.433 373.793C117.234 387.075 129.017 398.123 137.433 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M137.433 401.986C145.85 406.059 157.633 417.524 137.433 430.806C117.234 444.089 129.017 455.136 137.433 459" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 2C113.983 6.0724 125.767 17.5378 105.567 30.82C85.3671 44.1023 97.1503 55.15 105.567 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 59.012C113.983 63.0844 125.767 74.5497 105.567 87.832C85.3671 101.114 97.1503 112.162 105.567 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 116.027C113.983 120.1 125.767 131.565 105.567 144.847C85.3671 158.13 97.1503 169.177 105.567 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 173.041C113.983 177.113 125.767 188.579 105.567 201.861C85.3671 215.143 97.1503 226.191 105.567 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 230.054C113.983 234.19 125.767 245.835 105.567 259.325C85.3671 272.815 97.1503 284.035 105.567 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 287.958C113.983 292.031 125.767 303.496 105.567 316.778C85.3671 330.061 97.1503 341.108 105.567 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 344.973C113.983 349.045 125.767 360.51 105.567 373.793C85.3671 387.075 97.1503 398.123 105.567 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 401.986C113.983 406.059 125.767 417.524 105.567 430.806C85.3671 444.089 97.1503 455.136 105.567 459" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 2C83.1788 6.0724 94.962 17.5378 74.7622 30.82C54.5624 44.1023 66.3456 55.15 74.7622 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 59.012C83.1788 63.0844 94.962 74.5497 74.7622 87.832C54.5624 101.114 66.3456 112.162 74.7622 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 116.027C83.1788 120.1 94.962 131.565 74.7622 144.847C54.5624 158.13 66.3456 169.177 74.7622 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 173.041C83.1788 177.113 94.962 188.579 74.7622 201.861C54.5624 215.143 66.3456 226.191 74.7622 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 230.054C83.1788 234.19 94.962 245.835 74.7622 259.325C54.5624 272.815 66.3456 284.035 74.7622 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 287.958C83.1788 292.031 94.962 303.496 74.7622 316.778C54.5624 330.061 66.3456 341.108 74.7622 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 344.973C83.1788 349.045 94.962 360.51 74.7622 373.793C54.5624 387.075 66.3456 398.123 74.7622 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 401.986C83.1788 406.059 94.962 417.524 74.7622 430.806C54.5624 444.089 66.3456 455.136 74.7622 459" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 2C52.3743 6.0724 64.1575 17.5378 43.9577 30.82C23.758 44.1023 35.5412 55.15 43.9577 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 59.012C52.3743 63.0844 64.1575 74.5497 43.9577 87.832C23.758 101.114 35.5412 112.162 43.9577 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 116.027C52.3743 120.1 64.1575 131.565 43.9577 144.847C23.758 158.13 35.5412 169.177 43.9577 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 173.041C52.3743 177.113 64.1575 188.579 43.9577 201.861C23.758 215.143 35.5412 226.191 43.9577 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 230.054C52.3743 234.19 64.1575 245.835 43.9577 259.325C23.758 272.815 35.5412 284.035 43.9577 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 287.958C52.3743 292.031 64.1575 303.496 43.9577 316.778C23.758 330.061 35.5412 341.108 43.9577 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 344.973C52.3743 349.045 64.1575 360.51 43.9577 373.793C23.758 387.075 35.5412 398.123 43.9577 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 401.986C52.3743 406.059 64.1575 417.524 43.9577 430.806C23.758 444.089 35.5412 455.136 43.9577 459" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 2C21.5699 6.0724 33.3531 17.5378 13.1533 30.82C-7.04646 44.1023 4.73674 55.15 13.1533 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 59.012C21.5699 63.0844 33.3531 74.5497 13.1533 87.832C-7.04646 101.114 4.73674 112.162 13.1533 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 116.027C21.5699 120.1 33.3531 131.565 13.1533 144.847C-7.04646 158.13 4.73674 169.177 13.1533 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 173.041C21.5699 177.113 33.3531 188.579 13.1533 201.861C-7.04646 215.143 4.73674 226.191 13.1533 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 230.054C21.5699 234.19 33.3531 245.835 13.1533 259.325C-7.04646 272.815 4.73674 284.035 13.1533 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 287.958C21.5699 292.031 33.3531 303.496 13.1533 316.778C-7.04646 330.061 4.73674 341.108 13.1533 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 344.973C21.5699 349.045 33.3531 360.51 13.1533 373.793C-7.04646 387.075 4.73674 398.123 13.1533 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 401.986C21.5699 406.059 33.3531 417.524 13.1533 430.806C-7.04646 444.089 4.73674 455.136 13.1533 459" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 2C113.983 6.0724 125.767 17.5378 105.567 30.82C85.3671 44.1023 97.1503 55.15 105.567 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 59.012C113.983 63.0844 125.767 74.5497 105.567 87.832C85.3671 101.114 97.1503 112.162 105.567 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 116.027C113.983 120.1 125.767 131.565 105.567 144.847C85.3671 158.13 97.1503 169.177 105.567 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 173.041C113.983 177.113 125.767 188.579 105.567 201.861C85.3671 215.143 97.1503 226.191 105.567 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 230.054C113.983 234.19 125.767 245.835 105.567 259.325C85.3671 272.815 97.1503 284.035 105.567 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 287.958C113.983 292.031 125.767 303.496 105.567 316.778C85.3671 330.061 97.1503 341.108 105.567 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 344.973C113.983 349.045 125.767 360.51 105.567 373.793C85.3671 387.075 97.1503 398.123 105.567 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 401.986C113.983 406.059 125.767 417.524 105.567 430.806C85.3671 444.089 97.1503 455.136 105.567 459" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 2C83.1788 6.0724 94.962 17.5378 74.7622 30.82C54.5624 44.1023 66.3456 55.15 74.7622 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 59.012C83.1788 63.0844 94.962 74.5497 74.7622 87.832C54.5624 101.114 66.3456 112.162 74.7622 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 116.027C83.1788 120.1 94.962 131.565 74.7622 144.847C54.5624 158.13 66.3456 169.177 74.7622 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 173.041C83.1788 177.113 94.962 188.579 74.7622 201.861C54.5624 215.143 66.3456 226.191 74.7622 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 230.054C83.1788 234.19 94.962 245.835 74.7622 259.325C54.5624 272.815 66.3456 284.035 74.7622 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 287.958C83.1788 292.031 94.962 303.496 74.7622 316.778C54.5624 330.061 66.3456 341.108 74.7622 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 344.973C83.1788 349.045 94.962 360.51 74.7622 373.793C54.5624 387.075 66.3456 398.123 74.7622 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 401.986C83.1788 406.059 94.962 417.524 74.7622 430.806C54.5624 444.089 66.3456 455.136 74.7622 459" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 2C52.3743 6.0724 64.1575 17.5378 43.9577 30.82C23.758 44.1023 35.5412 55.15 43.9577 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 59.012C52.3743 63.0844 64.1575 74.5497 43.9577 87.832C23.758 101.114 35.5412 112.162 43.9577 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 116.027C52.3743 120.1 64.1575 131.565 43.9577 144.847C23.758 158.13 35.5412 169.177 43.9577 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 173.041C52.3743 177.113 64.1575 188.579 43.9577 201.861C23.758 215.143 35.5412 226.191 43.9577 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 230.054C52.3743 234.19 64.1575 245.835 43.9577 259.325C23.758 272.815 35.5412 284.035 43.9577 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 287.958C52.3743 292.031 64.1575 303.496 43.9577 316.778C23.758 330.061 35.5412 341.108 43.9577 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 344.973C52.3743 349.045 64.1575 360.51 43.9577 373.793C23.758 387.075 35.5412 398.123 43.9577 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 401.986C52.3743 406.059 64.1575 417.524 43.9577 430.806C23.758 444.089 35.5412 455.136 43.9577 459" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 2C21.5699 6.0724 33.3531 17.5378 13.1533 30.82C-7.04646 44.1023 4.73674 55.15 13.1533 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 59.012C21.5699 63.0844 33.3531 74.5497 13.1533 87.832C-7.04646 101.114 4.73674 112.162 13.1533 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 116.027C21.5699 120.1 33.3531 131.565 13.1533 144.847C-7.04646 158.13 4.73674 169.177 13.1533 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 173.041C21.5699 177.113 33.3531 188.579 13.1533 201.861C-7.04646 215.143 4.73674 226.191 13.1533 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 230.054C21.5699 234.19 33.3531 245.835 13.1533 259.325C-7.04646 272.815 4.73674 284.035 13.1533 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 287.958C21.5699 292.031 33.3531 303.496 13.1533 316.778C-7.04646 330.061 4.73674 341.108 13.1533 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 344.973C21.5699 349.045 33.3531 360.51 13.1533 373.793C-7.04646 387.075 4.73674 398.123 13.1533 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 401.986C21.5699 406.059 33.3531 417.524 13.1533 430.806C-7.04646 444.089 4.73674 455.136 13.1533 459" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 2C113.983 6.0724 125.767 17.5378 105.567 30.82C85.3671 44.1023 97.1503 55.15 105.567 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 59.012C113.983 63.0844 125.767 74.5497 105.567 87.832C85.3671 101.114 97.1503 112.162 105.567 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 116.027C113.983 120.1 125.767 131.565 105.567 144.847C85.3671 158.13 97.1503 169.177 105.567 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 173.041C113.983 177.113 125.767 188.579 105.567 201.861C85.3671 215.143 97.1503 226.191 105.567 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 230.054C113.983 234.19 125.767 245.835 105.567 259.325C85.3671 272.815 97.1503 284.035 105.567 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 287.958C113.983 292.031 125.767 303.496 105.567 316.778C85.3671 330.061 97.1503 341.108 105.567 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 344.973C113.983 349.045 125.767 360.51 105.567 373.793C85.3671 387.075 97.1503 398.123 105.567 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 401.986C113.983 406.059 125.767 417.524 105.567 430.806C85.3671 444.089 97.1503 455.136 105.567 459" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 2C83.1788 6.0724 94.962 17.5378 74.7622 30.82C54.5624 44.1023 66.3456 55.15 74.7622 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 59.012C83.1788 63.0844 94.962 74.5497 74.7622 87.832C54.5624 101.114 66.3456 112.162 74.7622 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 116.027C83.1788 120.1 94.962 131.565 74.7622 144.847C54.5624 158.13 66.3456 169.177 74.7622 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 173.041C83.1788 177.113 94.962 188.579 74.7622 201.861C54.5624 215.143 66.3456 226.191 74.7622 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 230.054C83.1788 234.19 94.962 245.835 74.7622 259.325C54.5624 272.815 66.3456 284.035 74.7622 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 287.958C83.1788 292.031 94.962 303.496 74.7622 316.778C54.5624 330.061 66.3456 341.108 74.7622 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 344.973C83.1788 349.045 94.962 360.51 74.7622 373.793C54.5624 387.075 66.3456 398.123 74.7622 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 401.986C83.1788 406.059 94.962 417.524 74.7622 430.806C54.5624 444.089 66.3456 455.136 74.7622 459" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 2C52.3743 6.0724 64.1575 17.5378 43.9577 30.82C23.758 44.1023 35.5412 55.15 43.9577 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 59.012C52.3743 63.0844 64.1575 74.5497 43.9577 87.832C23.758 101.114 35.5412 112.162 43.9577 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 116.027C52.3743 120.1 64.1575 131.565 43.9577 144.847C23.758 158.13 35.5412 169.177 43.9577 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 173.041C52.3743 177.113 64.1575 188.579 43.9577 201.861C23.758 215.143 35.5412 226.191 43.9577 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 230.054C52.3743 234.19 64.1575 245.835 43.9577 259.325C23.758 272.815 35.5412 284.035 43.9577 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 287.958C52.3743 292.031 64.1575 303.496 43.9577 316.778C23.758 330.061 35.5412 341.108 43.9577 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 344.973C52.3743 349.045 64.1575 360.51 43.9577 373.793C23.758 387.075 35.5412 398.123 43.9577 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 401.986C52.3743 406.059 64.1575 417.524 43.9577 430.806C23.758 444.089 35.5412 455.136 43.9577 459" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 2C21.5699 6.0724 33.3531 17.5378 13.1533 30.82C-7.04646 44.1023 4.73674 55.15 13.1533 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 59.012C21.5699 63.0844 33.3531 74.5497 13.1533 87.832C-7.04646 101.114 4.73674 112.162 13.1533 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 116.027C21.5699 120.1 33.3531 131.565 13.1533 144.847C-7.04646 158.13 4.73674 169.177 13.1533 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 173.041C21.5699 177.113 33.3531 188.579 13.1533 201.861C-7.04646 215.143 4.73674 226.191 13.1533 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 230.054C21.5699 234.19 33.3531 245.835 13.1533 259.325C-7.04646 272.815 4.73674 284.035 13.1533 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 287.958C21.5699 292.031 33.3531 303.496 13.1533 316.778C-7.04646 330.061 4.73674 341.108 13.1533 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 344.973C21.5699 349.045 33.3531 360.51 13.1533 373.793C-7.04646 387.075 4.73674 398.123 13.1533 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 401.986C21.5699 406.059 33.3531 417.524 13.1533 430.806C-7.04646 444.089 4.73674 455.136 13.1533 459" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 2C113.983 6.0724 125.767 17.5378 105.567 30.82C85.3671 44.1023 97.1503 55.15 105.567 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 59.012C113.983 63.0844 125.767 74.5497 105.567 87.832C85.3671 101.114 97.1503 112.162 105.567 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 116.027C113.983 120.1 125.767 131.565 105.567 144.847C85.3671 158.13 97.1503 169.177 105.567 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 173.041C113.983 177.113 125.767 188.579 105.567 201.861C85.3671 215.143 97.1503 226.191 105.567 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 230.054C113.983 234.19 125.767 245.835 105.567 259.325C85.3671 272.815 97.1503 284.035 105.567 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 287.958C113.983 292.031 125.767 303.496 105.567 316.778C85.3671 330.061 97.1503 341.108 105.567 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 344.973C113.983 349.045 125.767 360.51 105.567 373.793C85.3671 387.075 97.1503 398.123 105.567 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M105.567 401.986C113.983 406.059 125.767 417.524 105.567 430.806C85.3671 444.089 97.1503 455.136 105.567 459" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 2C83.1788 6.0724 94.962 17.5378 74.7622 30.82C54.5624 44.1023 66.3456 55.15 74.7622 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 59.012C83.1788 63.0844 94.962 74.5497 74.7622 87.832C54.5624 101.114 66.3456 112.162 74.7622 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 116.027C83.1788 120.1 94.962 131.565 74.7622 144.847C54.5624 158.13 66.3456 169.177 74.7622 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 173.041C83.1788 177.113 94.962 188.579 74.7622 201.861C54.5624 215.143 66.3456 226.191 74.7622 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 230.054C83.1788 234.19 94.962 245.835 74.7622 259.325C54.5624 272.815 66.3456 284.035 74.7622 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 287.958C83.1788 292.031 94.962 303.496 74.7622 316.778C54.5624 330.061 66.3456 341.108 74.7622 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 344.973C83.1788 349.045 94.962 360.51 74.7622 373.793C54.5624 387.075 66.3456 398.123 74.7622 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M74.7622 401.986C83.1788 406.059 94.962 417.524 74.7622 430.806C54.5624 444.089 66.3456 455.136 74.7622 459" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 2C52.3743 6.0724 64.1575 17.5378 43.9577 30.82C23.758 44.1023 35.5412 55.15 43.9577 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 59.012C52.3743 63.0844 64.1575 74.5497 43.9577 87.832C23.758 101.114 35.5412 112.162 43.9577 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 116.027C52.3743 120.1 64.1575 131.565 43.9577 144.847C23.758 158.13 35.5412 169.177 43.9577 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 173.041C52.3743 177.113 64.1575 188.579 43.9577 201.861C23.758 215.143 35.5412 226.191 43.9577 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 230.054C52.3743 234.19 64.1575 245.835 43.9577 259.325C23.758 272.815 35.5412 284.035 43.9577 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 287.958C52.3743 292.031 64.1575 303.496 43.9577 316.778C23.758 330.061 35.5412 341.108 43.9577 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 344.973C52.3743 349.045 64.1575 360.51 43.9577 373.793C23.758 387.075 35.5412 398.123 43.9577 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M43.9578 401.986C52.3743 406.059 64.1575 417.524 43.9577 430.806C23.758 444.089 35.5412 455.136 43.9577 459" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 2C21.5699 6.0724 33.3531 17.5378 13.1533 30.82C-7.04646 44.1023 4.73674 55.15 13.1533 59.0135" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 59.012C21.5699 63.0844 33.3531 74.5497 13.1533 87.832C-7.04646 101.114 4.73674 112.162 13.1533 116.026" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 116.027C21.5699 120.1 33.3531 131.565 13.1533 144.847C-7.04646 158.13 4.73674 169.177 13.1533 173.041" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 173.041C21.5699 177.113 33.3531 188.579 13.1533 201.861C-7.04646 215.143 4.73674 226.191 13.1533 230.054" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 230.054C21.5699 234.19 33.3531 245.835 13.1533 259.325C-7.04646 272.815 4.73674 284.035 13.1533 287.959" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 287.958C21.5699 292.031 33.3531 303.496 13.1533 316.778C-7.04646 330.061 4.73674 341.108 13.1533 344.972" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 344.973C21.5699 349.045 33.3531 360.51 13.1533 373.793C-7.04646 387.075 4.73674 398.123 13.1533 401.986" stroke="#003E46" strokeWidth="3" />
        <path d="M13.1533 401.986C21.5699 406.059 33.3531 417.524 13.1533 430.806C-7.04646 444.089 4.73674 455.136 13.1533 459" stroke="#003E46" strokeWidth="3" />
    </svg>
)

export const CursiveLines = () => (
    <svg width="364" height="219" viewBox="0 0 364 219" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 12.0333C5.20801 4.46194 14.2398 -6.138 24.7029 12.0333C35.1659 30.2047 43.8687 19.6047 46.9122 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 12.0333C50.1191 4.46194 59.1509 -6.138 69.614 12.0333C80.0771 30.2047 88.7798 19.6047 91.8233 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 12.0333C95.0322 4.46194 104.064 -6.138 114.527 12.0333C124.99 30.2047 133.693 19.6047 136.736 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 12.0333C139.945 4.46194 148.977 -6.138 159.44 12.0333C169.903 30.2047 178.606 19.6047 181.65 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 12.0333C184.908 4.46194 194.08 -6.138 204.707 12.0333C215.334 30.2047 224.172 19.6047 227.263 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 12.0333C230.471 4.46194 239.503 -6.138 249.966 12.0333C260.429 30.2047 269.131 19.6047 272.175 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 12.0333C275.384 4.46194 284.416 -6.138 294.879 12.0333C305.342 30.2047 314.044 19.6047 317.088 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 12.0333C320.296 4.46194 329.328 -6.138 339.791 12.0333C350.254 30.2047 358.957 19.6047 362 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M2 39.7443C5.20801 32.1729 14.2398 21.5729 24.7029 39.7443C35.1659 57.9156 43.8687 47.3157 46.9122 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 39.7443C50.1191 32.1729 59.1509 21.5729 69.614 39.7443C80.0771 57.9156 88.7798 47.3157 91.8233 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 39.7443C95.0322 32.1729 104.064 21.5729 114.527 39.7443C124.99 57.9156 133.693 47.3157 136.736 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 39.7443C139.945 32.1729 148.977 21.5729 159.44 39.7443C169.903 57.9156 178.606 47.3157 181.65 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 39.7443C184.908 32.1729 194.08 21.5729 204.707 39.7443C215.334 57.9156 224.172 47.3157 227.263 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 39.7443C230.471 32.1729 239.503 21.5729 249.966 39.7443C260.429 57.9156 269.131 47.3157 272.175 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 39.7443C275.384 32.1729 284.416 21.5729 294.879 39.7443C305.342 57.9156 314.044 47.3157 317.088 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 39.7443C320.296 32.1729 329.328 21.5729 339.791 39.7443C350.254 57.9156 358.957 47.3157 362 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M2 67.4552C5.20801 59.8838 14.2398 49.2839 24.7029 67.4552C35.1659 85.6265 43.8687 75.0266 46.9122 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 67.4552C50.1191 59.8838 59.1509 49.2839 69.614 67.4552C80.0771 85.6265 88.7798 75.0266 91.8233 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 67.4552C95.0322 59.8838 104.064 49.2839 114.527 67.4552C124.99 85.6265 133.693 75.0266 136.736 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 67.4552C139.945 59.8838 148.977 49.2839 159.44 67.4552C169.903 85.6265 178.606 75.0266 181.65 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 67.4552C184.908 59.8838 194.08 49.2839 204.707 67.4552C215.334 85.6265 224.172 75.0266 227.263 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 67.4552C230.471 59.8838 239.503 49.2839 249.966 67.4552C260.429 85.6265 269.131 75.0266 272.175 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 67.4552C275.384 59.8838 284.416 49.2839 294.879 67.4552C305.342 85.6265 314.044 75.0266 317.088 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 67.4552C320.296 59.8838 329.328 49.2839 339.791 67.4552C350.254 85.6265 358.957 75.0266 362 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M2 95.1671C5.20801 87.5957 14.2398 76.9958 24.7029 95.1671C35.1659 113.338 43.8687 102.739 46.9122 95.1671" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 95.1661C50.1191 87.5948 59.1509 76.9948 69.614 95.1661C80.0771 113.337 88.7798 102.738 91.8233 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 95.1661C95.0322 87.5948 104.064 76.9948 114.527 95.1661C124.99 113.337 133.693 102.738 136.736 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 95.1661C139.945 87.5948 148.977 76.9948 159.44 95.1661C169.903 113.337 178.606 102.738 181.65 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 95.1661C184.908 87.5948 194.08 76.9948 204.707 95.1661C215.334 113.337 224.172 102.738 227.263 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 95.1661C230.471 87.5948 239.503 76.9948 249.966 95.1661C260.429 113.337 269.131 102.738 272.175 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 95.1661C275.384 87.5948 284.416 76.9948 294.879 95.1661C305.342 113.337 314.044 102.738 317.088 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 95.1661C320.296 87.5948 329.328 76.9948 339.791 95.1661C350.254 113.337 358.957 102.738 362 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M2 12.0333C5.20801 4.46194 14.2398 -6.138 24.7029 12.0333C35.1659 30.2047 43.8687 19.6047 46.9122 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 12.0333C50.1191 4.46194 59.1509 -6.138 69.614 12.0333C80.0771 30.2047 88.7798 19.6047 91.8233 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 12.0333C95.0322 4.46194 104.064 -6.138 114.527 12.0333C124.99 30.2047 133.693 19.6047 136.736 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 12.0333C139.945 4.46194 148.977 -6.138 159.44 12.0333C169.903 30.2047 178.606 19.6047 181.65 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 12.0333C184.908 4.46194 194.08 -6.138 204.707 12.0333C215.334 30.2047 224.172 19.6047 227.263 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 12.0333C230.471 4.46194 239.503 -6.138 249.966 12.0333C260.429 30.2047 269.131 19.6047 272.175 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 12.0333C275.384 4.46194 284.416 -6.138 294.879 12.0333C305.342 30.2047 314.044 19.6047 317.088 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 12.0333C320.296 4.46194 329.328 -6.138 339.791 12.0333C350.254 30.2047 358.957 19.6047 362 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M2 39.7443C5.20801 32.1729 14.2398 21.5729 24.7029 39.7443C35.1659 57.9156 43.8687 47.3157 46.9122 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 39.7443C50.1191 32.1729 59.1509 21.5729 69.614 39.7443C80.0771 57.9156 88.7798 47.3157 91.8233 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 39.7443C95.0322 32.1729 104.064 21.5729 114.527 39.7443C124.99 57.9156 133.693 47.3157 136.736 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 39.7443C139.945 32.1729 148.977 21.5729 159.44 39.7443C169.903 57.9156 178.606 47.3157 181.65 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 39.7443C184.908 32.1729 194.08 21.5729 204.707 39.7443C215.334 57.9156 224.172 47.3157 227.263 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 39.7443C230.471 32.1729 239.503 21.5729 249.966 39.7443C260.429 57.9156 269.131 47.3157 272.175 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 39.7443C275.384 32.1729 284.416 21.5729 294.879 39.7443C305.342 57.9156 314.044 47.3157 317.088 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 39.7443C320.296 32.1729 329.328 21.5729 339.791 39.7443C350.254 57.9156 358.957 47.3157 362 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M2 67.4552C5.20801 59.8838 14.2398 49.2839 24.7029 67.4552C35.1659 85.6265 43.8687 75.0266 46.9122 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 67.4552C50.1191 59.8838 59.1509 49.2839 69.614 67.4552C80.0771 85.6265 88.7798 75.0266 91.8233 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 67.4552C95.0322 59.8838 104.064 49.2839 114.527 67.4552C124.99 85.6265 133.693 75.0266 136.736 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 67.4552C139.945 59.8838 148.977 49.2839 159.44 67.4552C169.903 85.6265 178.606 75.0266 181.65 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 67.4552C184.908 59.8838 194.08 49.2839 204.707 67.4552C215.334 85.6265 224.172 75.0266 227.263 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 67.4552C230.471 59.8838 239.503 49.2839 249.966 67.4552C260.429 85.6265 269.131 75.0266 272.175 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 67.4552C275.384 59.8838 284.416 49.2839 294.879 67.4552C305.342 85.6265 314.044 75.0266 317.088 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 67.4552C320.296 59.8838 329.328 49.2839 339.791 67.4552C350.254 85.6265 358.957 75.0266 362 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M2 95.1671C5.20801 87.5957 14.2398 76.9958 24.7029 95.1671C35.1659 113.338 43.8687 102.739 46.9122 95.1671" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 95.1661C50.1191 87.5948 59.1509 76.9948 69.614 95.1661C80.0771 113.337 88.7798 102.738 91.8233 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 95.1661C95.0322 87.5948 104.064 76.9948 114.527 95.1661C124.99 113.337 133.693 102.738 136.736 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 95.1661C139.945 87.5948 148.977 76.9948 159.44 95.1661C169.903 113.337 178.606 102.738 181.65 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 95.1661C184.908 87.5948 194.08 76.9948 204.707 95.1661C215.334 113.337 224.172 102.738 227.263 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 95.1661C230.471 87.5948 239.503 76.9948 249.966 95.1661C260.429 113.337 269.131 102.738 272.175 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 95.1661C275.384 87.5948 284.416 76.9948 294.879 95.1661C305.342 113.337 314.044 102.738 317.088 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 95.1661C320.296 87.5948 329.328 76.9948 339.791 95.1661C350.254 113.337 358.957 102.738 362 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M2 12.0333C5.20801 4.46194 14.2398 -6.138 24.7029 12.0333C35.1659 30.2047 43.8687 19.6047 46.9122 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 12.0333C50.1191 4.46194 59.1509 -6.138 69.614 12.0333C80.0771 30.2047 88.7798 19.6047 91.8233 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 12.0333C95.0322 4.46194 104.064 -6.138 114.527 12.0333C124.99 30.2047 133.693 19.6047 136.736 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 12.0333C139.945 4.46194 148.977 -6.138 159.44 12.0333C169.903 30.2047 178.606 19.6047 181.65 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 12.0333C184.908 4.46194 194.08 -6.138 204.707 12.0333C215.334 30.2047 224.172 19.6047 227.263 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 12.0333C230.471 4.46194 239.503 -6.138 249.966 12.0333C260.429 30.2047 269.131 19.6047 272.175 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 12.0333C275.384 4.46194 284.416 -6.138 294.879 12.0333C305.342 30.2047 314.044 19.6047 317.088 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 12.0333C320.296 4.46194 329.328 -6.138 339.791 12.0333C350.254 30.2047 358.957 19.6047 362 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M2 39.7443C5.20801 32.1729 14.2398 21.5729 24.7029 39.7443C35.1659 57.9156 43.8687 47.3157 46.9122 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 39.7443C50.1191 32.1729 59.1509 21.5729 69.614 39.7443C80.0771 57.9156 88.7798 47.3157 91.8233 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 39.7443C95.0322 32.1729 104.064 21.5729 114.527 39.7443C124.99 57.9156 133.693 47.3157 136.736 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 39.7443C139.945 32.1729 148.977 21.5729 159.44 39.7443C169.903 57.9156 178.606 47.3157 181.65 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 39.7443C184.908 32.1729 194.08 21.5729 204.707 39.7443C215.334 57.9156 224.172 47.3157 227.263 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 39.7443C230.471 32.1729 239.503 21.5729 249.966 39.7443C260.429 57.9156 269.131 47.3157 272.175 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 39.7443C275.384 32.1729 284.416 21.5729 294.879 39.7443C305.342 57.9156 314.044 47.3157 317.088 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 39.7443C320.296 32.1729 329.328 21.5729 339.791 39.7443C350.254 57.9156 358.957 47.3157 362 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M2 67.4552C5.20801 59.8838 14.2398 49.2839 24.7029 67.4552C35.1659 85.6265 43.8687 75.0266 46.9122 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 67.4552C50.1191 59.8838 59.1509 49.2839 69.614 67.4552C80.0771 85.6265 88.7798 75.0266 91.8233 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 67.4552C95.0322 59.8838 104.064 49.2839 114.527 67.4552C124.99 85.6265 133.693 75.0266 136.736 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 67.4552C139.945 59.8838 148.977 49.2839 159.44 67.4552C169.903 85.6265 178.606 75.0266 181.65 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 67.4552C184.908 59.8838 194.08 49.2839 204.707 67.4552C215.334 85.6265 224.172 75.0266 227.263 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 67.4552C230.471 59.8838 239.503 49.2839 249.966 67.4552C260.429 85.6265 269.131 75.0266 272.175 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 67.4552C275.384 59.8838 284.416 49.2839 294.879 67.4552C305.342 85.6265 314.044 75.0266 317.088 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 67.4552C320.296 59.8838 329.328 49.2839 339.791 67.4552C350.254 85.6265 358.957 75.0266 362 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M2 95.1671C5.20801 87.5957 14.2398 76.9958 24.7029 95.1671C35.1659 113.338 43.8687 102.739 46.9122 95.1671" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 95.1661C50.1191 87.5948 59.1509 76.9948 69.614 95.1661C80.0771 113.337 88.7798 102.738 91.8233 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 95.1661C95.0322 87.5948 104.064 76.9948 114.527 95.1661C124.99 113.337 133.693 102.738 136.736 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 95.1661C139.945 87.5948 148.977 76.9948 159.44 95.1661C169.903 113.337 178.606 102.738 181.65 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 95.1661C184.908 87.5948 194.08 76.9948 204.707 95.1661C215.334 113.337 224.172 102.738 227.263 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 95.1661C230.471 87.5948 239.503 76.9948 249.966 95.1661C260.429 113.337 269.131 102.738 272.175 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 95.1661C275.384 87.5948 284.416 76.9948 294.879 95.1661C305.342 113.337 314.044 102.738 317.088 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 95.1661C320.296 87.5948 329.328 76.9948 339.791 95.1661C350.254 113.337 358.957 102.738 362 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M2 12.0333C5.20801 4.46194 14.2398 -6.138 24.7029 12.0333C35.1659 30.2047 43.8687 19.6047 46.9122 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 12.0333C50.1191 4.46194 59.1509 -6.138 69.614 12.0333C80.0771 30.2047 88.7798 19.6047 91.8233 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 12.0333C95.0322 4.46194 104.064 -6.138 114.527 12.0333C124.99 30.2047 133.693 19.6047 136.736 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 12.0333C139.945 4.46194 148.977 -6.138 159.44 12.0333C169.903 30.2047 178.606 19.6047 181.65 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 12.0333C184.908 4.46194 194.08 -6.138 204.707 12.0333C215.334 30.2047 224.172 19.6047 227.263 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 12.0333C230.471 4.46194 239.503 -6.138 249.966 12.0333C260.429 30.2047 269.131 19.6047 272.175 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 12.0333C275.384 4.46194 284.416 -6.138 294.879 12.0333C305.342 30.2047 314.044 19.6047 317.088 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 12.0333C320.296 4.46194 329.328 -6.138 339.791 12.0333C350.254 30.2047 358.957 19.6047 362 12.0333" stroke="#003E46" stroke-width="3" />
        <path d="M2 39.7443C5.20801 32.1729 14.2398 21.5729 24.7029 39.7443C35.1659 57.9156 43.8687 47.3157 46.9122 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 39.7443C50.1191 32.1729 59.1509 21.5729 69.614 39.7443C80.0771 57.9156 88.7798 47.3157 91.8233 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 39.7443C95.0322 32.1729 104.064 21.5729 114.527 39.7443C124.99 57.9156 133.693 47.3157 136.736 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 39.7443C139.945 32.1729 148.977 21.5729 159.44 39.7443C169.903 57.9156 178.606 47.3157 181.65 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 39.7443C184.908 32.1729 194.08 21.5729 204.707 39.7443C215.334 57.9156 224.172 47.3157 227.263 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 39.7443C230.471 32.1729 239.503 21.5729 249.966 39.7443C260.429 57.9156 269.131 47.3157 272.175 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 39.7443C275.384 32.1729 284.416 21.5729 294.879 39.7443C305.342 57.9156 314.044 47.3157 317.088 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 39.7443C320.296 32.1729 329.328 21.5729 339.791 39.7443C350.254 57.9156 358.957 47.3157 362 39.7443" stroke="#003E46" stroke-width="3" />
        <path d="M2 67.4552C5.20801 59.8838 14.2398 49.2839 24.7029 67.4552C35.1659 85.6265 43.8687 75.0266 46.9122 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 67.4552C50.1191 59.8838 59.1509 49.2839 69.614 67.4552C80.0771 85.6265 88.7798 75.0266 91.8233 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 67.4552C95.0322 59.8838 104.064 49.2839 114.527 67.4552C124.99 85.6265 133.693 75.0266 136.736 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 67.4552C139.945 59.8838 148.977 49.2839 159.44 67.4552C169.903 85.6265 178.606 75.0266 181.65 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 67.4552C184.908 59.8838 194.08 49.2839 204.707 67.4552C215.334 85.6265 224.172 75.0266 227.263 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 67.4552C230.471 59.8838 239.503 49.2839 249.966 67.4552C260.429 85.6265 269.131 75.0266 272.175 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 67.4552C275.384 59.8838 284.416 49.2839 294.879 67.4552C305.342 85.6265 314.044 75.0266 317.088 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 67.4552C320.296 59.8838 329.328 49.2839 339.791 67.4552C350.254 85.6265 358.957 75.0266 362 67.4552" stroke="#003E46" stroke-width="3" />
        <path d="M2 95.1671C5.20801 87.5957 14.2398 76.9958 24.7029 95.1671C35.1659 113.338 43.8687 102.739 46.9122 95.1671" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 95.1661C50.1191 87.5948 59.1509 76.9948 69.614 95.1661C80.0771 113.337 88.7798 102.738 91.8233 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 95.1661C95.0322 87.5948 104.064 76.9948 114.527 95.1661C124.99 113.337 133.693 102.738 136.736 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 95.1661C139.945 87.5948 148.977 76.9948 159.44 95.1661C169.903 113.337 178.606 102.738 181.65 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 95.1661C184.908 87.5948 194.08 76.9948 204.707 95.1661C215.334 113.337 224.172 102.738 227.263 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 95.1661C230.471 87.5948 239.503 76.9948 249.966 95.1661C260.429 113.337 269.131 102.738 272.175 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 95.1661C275.384 87.5948 284.416 76.9948 294.879 95.1661C305.342 113.337 314.044 102.738 317.088 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 95.1661C320.296 87.5948 329.328 76.9948 339.791 95.1661C350.254 113.337 358.957 102.738 362 95.1661" stroke="#003E46" stroke-width="3" />
        <path d="M2 123.833C5.20801 116.262 14.2398 105.662 24.7029 123.833C35.1659 142.004 43.8687 131.405 46.9122 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 123.833C50.1191 116.262 59.1509 105.662 69.614 123.833C80.0771 142.004 88.7798 131.405 91.8233 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 123.833C95.0322 116.262 104.064 105.662 114.527 123.833C124.99 142.004 133.693 131.405 136.736 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 123.833C139.945 116.262 148.977 105.662 159.44 123.833C169.903 142.004 178.606 131.405 181.65 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 123.833C184.908 116.262 194.08 105.662 204.707 123.833C215.334 142.004 224.172 131.405 227.263 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 123.833C230.471 116.262 239.503 105.662 249.966 123.833C260.429 142.004 269.131 131.405 272.175 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 123.833C275.384 116.262 284.416 105.662 294.879 123.833C305.342 142.004 314.044 131.405 317.088 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 123.833C320.296 116.262 329.328 105.662 339.791 123.833C350.254 142.004 358.957 131.405 362 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M2 151.544C5.20801 143.973 14.2398 133.373 24.7029 151.544C35.1659 169.715 43.8687 159.115 46.9122 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 151.544C50.1191 143.973 59.1509 133.373 69.614 151.544C80.0771 169.715 88.7798 159.115 91.8233 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 151.544C95.0322 143.973 104.064 133.373 114.527 151.544C124.99 169.715 133.693 159.115 136.736 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 151.544C139.945 143.973 148.977 133.373 159.44 151.544C169.903 169.715 178.606 159.115 181.65 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 151.544C184.908 143.973 194.08 133.373 204.707 151.544C215.334 169.715 224.172 159.115 227.263 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 151.544C230.471 143.973 239.503 133.373 249.966 151.544C260.429 169.715 269.131 159.115 272.175 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 151.544C275.384 143.973 284.416 133.373 294.879 151.544C305.342 169.715 314.044 159.115 317.088 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 151.544C320.296 143.973 329.328 133.373 339.791 151.544C350.254 169.715 358.957 159.115 362 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M2 179.255C5.20801 171.684 14.2398 161.084 24.7029 179.255C35.1659 197.426 43.8687 186.826 46.9122 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 179.255C50.1191 171.684 59.1509 161.084 69.614 179.255C80.0771 197.426 88.7798 186.826 91.8233 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 179.255C95.0322 171.684 104.064 161.084 114.527 179.255C124.99 197.426 133.693 186.826 136.736 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 179.255C139.945 171.684 148.977 161.084 159.44 179.255C169.903 197.426 178.606 186.826 181.65 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 179.255C184.908 171.684 194.08 161.084 204.707 179.255C215.334 197.426 224.172 186.826 227.263 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 179.255C230.471 171.684 239.503 161.084 249.966 179.255C260.429 197.426 269.131 186.826 272.175 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 179.255C275.384 171.684 284.416 161.084 294.879 179.255C305.342 197.426 314.044 186.826 317.088 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 179.255C320.296 171.684 329.328 161.084 339.791 179.255C350.254 197.426 358.957 186.826 362 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M2 206.967C5.20801 199.396 14.2398 188.796 24.7029 206.967C35.1659 225.138 43.8687 214.538 46.9122 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 206.967C50.1191 199.396 59.1509 188.796 69.614 206.967C80.0771 225.138 88.7798 214.538 91.8233 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 206.967C95.0322 199.396 104.064 188.796 114.527 206.967C124.99 225.138 133.693 214.538 136.736 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 206.967C139.945 199.396 148.977 188.796 159.44 206.967C169.903 225.138 178.606 214.538 181.65 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 206.967C184.908 199.396 194.08 188.796 204.707 206.967C215.334 225.138 224.172 214.538 227.263 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 206.967C230.471 199.396 239.503 188.796 249.966 206.967C260.429 225.138 269.131 214.538 272.175 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 206.967C275.384 199.396 284.416 188.796 294.879 206.967C305.342 225.138 314.044 214.538 317.088 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 206.967C320.296 199.396 329.328 188.796 339.791 206.967C350.254 225.138 358.957 214.538 362 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M2 123.833C5.20801 116.262 14.2398 105.662 24.7029 123.833C35.1659 142.004 43.8687 131.405 46.9122 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 123.833C50.1191 116.262 59.1509 105.662 69.614 123.833C80.0771 142.004 88.7798 131.405 91.8233 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 123.833C95.0322 116.262 104.064 105.662 114.527 123.833C124.99 142.004 133.693 131.405 136.736 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 123.833C139.945 116.262 148.977 105.662 159.44 123.833C169.903 142.004 178.606 131.405 181.65 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 123.833C184.908 116.262 194.08 105.662 204.707 123.833C215.334 142.004 224.172 131.405 227.263 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 123.833C230.471 116.262 239.503 105.662 249.966 123.833C260.429 142.004 269.131 131.405 272.175 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 123.833C275.384 116.262 284.416 105.662 294.879 123.833C305.342 142.004 314.044 131.405 317.088 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 123.833C320.296 116.262 329.328 105.662 339.791 123.833C350.254 142.004 358.957 131.405 362 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M2 151.544C5.20801 143.973 14.2398 133.373 24.7029 151.544C35.1659 169.715 43.8687 159.115 46.9122 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 151.544C50.1191 143.973 59.1509 133.373 69.614 151.544C80.0771 169.715 88.7798 159.115 91.8233 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 151.544C95.0322 143.973 104.064 133.373 114.527 151.544C124.99 169.715 133.693 159.115 136.736 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 151.544C139.945 143.973 148.977 133.373 159.44 151.544C169.903 169.715 178.606 159.115 181.65 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 151.544C184.908 143.973 194.08 133.373 204.707 151.544C215.334 169.715 224.172 159.115 227.263 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 151.544C230.471 143.973 239.503 133.373 249.966 151.544C260.429 169.715 269.131 159.115 272.175 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 151.544C275.384 143.973 284.416 133.373 294.879 151.544C305.342 169.715 314.044 159.115 317.088 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 151.544C320.296 143.973 329.328 133.373 339.791 151.544C350.254 169.715 358.957 159.115 362 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M2 179.255C5.20801 171.684 14.2398 161.084 24.7029 179.255C35.1659 197.426 43.8687 186.826 46.9122 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 179.255C50.1191 171.684 59.1509 161.084 69.614 179.255C80.0771 197.426 88.7798 186.826 91.8233 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 179.255C95.0322 171.684 104.064 161.084 114.527 179.255C124.99 197.426 133.693 186.826 136.736 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 179.255C139.945 171.684 148.977 161.084 159.44 179.255C169.903 197.426 178.606 186.826 181.65 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 179.255C184.908 171.684 194.08 161.084 204.707 179.255C215.334 197.426 224.172 186.826 227.263 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 179.255C230.471 171.684 239.503 161.084 249.966 179.255C260.429 197.426 269.131 186.826 272.175 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 179.255C275.384 171.684 284.416 161.084 294.879 179.255C305.342 197.426 314.044 186.826 317.088 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 179.255C320.296 171.684 329.328 161.084 339.791 179.255C350.254 197.426 358.957 186.826 362 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M2 206.967C5.20801 199.396 14.2398 188.796 24.7029 206.967C35.1659 225.138 43.8687 214.538 46.9122 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 206.967C50.1191 199.396 59.1509 188.796 69.614 206.967C80.0771 225.138 88.7798 214.538 91.8233 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 206.967C95.0322 199.396 104.064 188.796 114.527 206.967C124.99 225.138 133.693 214.538 136.736 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 206.967C139.945 199.396 148.977 188.796 159.44 206.967C169.903 225.138 178.606 214.538 181.65 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 206.967C184.908 199.396 194.08 188.796 204.707 206.967C215.334 225.138 224.172 214.538 227.263 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 206.967C230.471 199.396 239.503 188.796 249.966 206.967C260.429 225.138 269.131 214.538 272.175 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 206.967C275.384 199.396 284.416 188.796 294.879 206.967C305.342 225.138 314.044 214.538 317.088 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 206.967C320.296 199.396 329.328 188.796 339.791 206.967C350.254 225.138 358.957 214.538 362 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M2 123.833C5.20801 116.262 14.2398 105.662 24.7029 123.833C35.1659 142.004 43.8687 131.405 46.9122 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 123.833C50.1191 116.262 59.1509 105.662 69.614 123.833C80.0771 142.004 88.7798 131.405 91.8233 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 123.833C95.0322 116.262 104.064 105.662 114.527 123.833C124.99 142.004 133.693 131.405 136.736 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 123.833C139.945 116.262 148.977 105.662 159.44 123.833C169.903 142.004 178.606 131.405 181.65 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 123.833C184.908 116.262 194.08 105.662 204.707 123.833C215.334 142.004 224.172 131.405 227.263 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 123.833C230.471 116.262 239.503 105.662 249.966 123.833C260.429 142.004 269.131 131.405 272.175 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 123.833C275.384 116.262 284.416 105.662 294.879 123.833C305.342 142.004 314.044 131.405 317.088 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 123.833C320.296 116.262 329.328 105.662 339.791 123.833C350.254 142.004 358.957 131.405 362 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M2 151.544C5.20801 143.973 14.2398 133.373 24.7029 151.544C35.1659 169.715 43.8687 159.115 46.9122 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 151.544C50.1191 143.973 59.1509 133.373 69.614 151.544C80.0771 169.715 88.7798 159.115 91.8233 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 151.544C95.0322 143.973 104.064 133.373 114.527 151.544C124.99 169.715 133.693 159.115 136.736 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 151.544C139.945 143.973 148.977 133.373 159.44 151.544C169.903 169.715 178.606 159.115 181.65 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 151.544C184.908 143.973 194.08 133.373 204.707 151.544C215.334 169.715 224.172 159.115 227.263 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 151.544C230.471 143.973 239.503 133.373 249.966 151.544C260.429 169.715 269.131 159.115 272.175 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 151.544C275.384 143.973 284.416 133.373 294.879 151.544C305.342 169.715 314.044 159.115 317.088 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 151.544C320.296 143.973 329.328 133.373 339.791 151.544C350.254 169.715 358.957 159.115 362 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M2 179.255C5.20801 171.684 14.2398 161.084 24.7029 179.255C35.1659 197.426 43.8687 186.826 46.9122 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 179.255C50.1191 171.684 59.1509 161.084 69.614 179.255C80.0771 197.426 88.7798 186.826 91.8233 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 179.255C95.0322 171.684 104.064 161.084 114.527 179.255C124.99 197.426 133.693 186.826 136.736 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 179.255C139.945 171.684 148.977 161.084 159.44 179.255C169.903 197.426 178.606 186.826 181.65 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 179.255C184.908 171.684 194.08 161.084 204.707 179.255C215.334 197.426 224.172 186.826 227.263 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 179.255C230.471 171.684 239.503 161.084 249.966 179.255C260.429 197.426 269.131 186.826 272.175 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 179.255C275.384 171.684 284.416 161.084 294.879 179.255C305.342 197.426 314.044 186.826 317.088 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 179.255C320.296 171.684 329.328 161.084 339.791 179.255C350.254 197.426 358.957 186.826 362 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M2 206.967C5.20801 199.396 14.2398 188.796 24.7029 206.967C35.1659 225.138 43.8687 214.538 46.9122 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 206.967C50.1191 199.396 59.1509 188.796 69.614 206.967C80.0771 225.138 88.7798 214.538 91.8233 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 206.967C95.0322 199.396 104.064 188.796 114.527 206.967C124.99 225.138 133.693 214.538 136.736 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 206.967C139.945 199.396 148.977 188.796 159.44 206.967C169.903 225.138 178.606 214.538 181.65 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 206.967C184.908 199.396 194.08 188.796 204.707 206.967C215.334 225.138 224.172 214.538 227.263 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 206.967C230.471 199.396 239.503 188.796 249.966 206.967C260.429 225.138 269.131 214.538 272.175 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 206.967C275.384 199.396 284.416 188.796 294.879 206.967C305.342 225.138 314.044 214.538 317.088 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 206.967C320.296 199.396 329.328 188.796 339.791 206.967C350.254 225.138 358.957 214.538 362 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M2 123.833C5.20801 116.262 14.2398 105.662 24.7029 123.833C35.1659 142.004 43.8687 131.405 46.9122 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 123.833C50.1191 116.262 59.1509 105.662 69.614 123.833C80.0771 142.004 88.7798 131.405 91.8233 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 123.833C95.0322 116.262 104.064 105.662 114.527 123.833C124.99 142.004 133.693 131.405 136.736 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 123.833C139.945 116.262 148.977 105.662 159.44 123.833C169.903 142.004 178.606 131.405 181.65 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 123.833C184.908 116.262 194.08 105.662 204.707 123.833C215.334 142.004 224.172 131.405 227.263 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 123.833C230.471 116.262 239.503 105.662 249.966 123.833C260.429 142.004 269.131 131.405 272.175 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 123.833C275.384 116.262 284.416 105.662 294.879 123.833C305.342 142.004 314.044 131.405 317.088 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 123.833C320.296 116.262 329.328 105.662 339.791 123.833C350.254 142.004 358.957 131.405 362 123.833" stroke="#003E46" stroke-width="3" />
        <path d="M2 151.544C5.20801 143.973 14.2398 133.373 24.7029 151.544C35.1659 169.715 43.8687 159.115 46.9122 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 151.544C50.1191 143.973 59.1509 133.373 69.614 151.544C80.0771 169.715 88.7798 159.115 91.8233 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 151.544C95.0322 143.973 104.064 133.373 114.527 151.544C124.99 169.715 133.693 159.115 136.736 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 151.544C139.945 143.973 148.977 133.373 159.44 151.544C169.903 169.715 178.606 159.115 181.65 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 151.544C184.908 143.973 194.08 133.373 204.707 151.544C215.334 169.715 224.172 159.115 227.263 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 151.544C230.471 143.973 239.503 133.373 249.966 151.544C260.429 169.715 269.131 159.115 272.175 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 151.544C275.384 143.973 284.416 133.373 294.879 151.544C305.342 169.715 314.044 159.115 317.088 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 151.544C320.296 143.973 329.328 133.373 339.791 151.544C350.254 169.715 358.957 159.115 362 151.544" stroke="#003E46" stroke-width="3" />
        <path d="M2 179.255C5.20801 171.684 14.2398 161.084 24.7029 179.255C35.1659 197.426 43.8687 186.826 46.9122 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 179.255C50.1191 171.684 59.1509 161.084 69.614 179.255C80.0771 197.426 88.7798 186.826 91.8233 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 179.255C95.0322 171.684 104.064 161.084 114.527 179.255C124.99 197.426 133.693 186.826 136.736 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 179.255C139.945 171.684 148.977 161.084 159.44 179.255C169.903 197.426 178.606 186.826 181.65 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 179.255C184.908 171.684 194.08 161.084 204.707 179.255C215.334 197.426 224.172 186.826 227.263 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 179.255C230.471 171.684 239.503 161.084 249.966 179.255C260.429 197.426 269.131 186.826 272.175 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 179.255C275.384 171.684 284.416 161.084 294.879 179.255C305.342 197.426 314.044 186.826 317.088 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 179.255C320.296 171.684 329.328 161.084 339.791 179.255C350.254 197.426 358.957 186.826 362 179.255" stroke="#003E46" stroke-width="3" />
        <path d="M2 206.967C5.20801 199.396 14.2398 188.796 24.7029 206.967C35.1659 225.138 43.8687 214.538 46.9122 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M46.9111 206.967C50.1191 199.396 59.1509 188.796 69.614 206.967C80.0771 225.138 88.7798 214.538 91.8233 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M91.8242 206.967C95.0322 199.396 104.064 188.796 114.527 206.967C124.99 225.138 133.693 214.538 136.736 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M136.737 206.967C139.945 199.396 148.977 188.796 159.44 206.967C169.903 225.138 178.606 214.538 181.65 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M181.649 206.967C184.908 199.396 194.08 188.796 204.707 206.967C215.334 225.138 224.172 214.538 227.263 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M227.263 206.967C230.471 199.396 239.503 188.796 249.966 206.967C260.429 225.138 269.131 214.538 272.175 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M272.176 206.967C275.384 199.396 284.416 188.796 294.879 206.967C305.342 225.138 314.044 214.538 317.088 206.967" stroke="#003E46" stroke-width="3" />
        <path d="M317.088 206.967C320.296 199.396 329.328 188.796 339.791 206.967C350.254 225.138 358.957 214.538 362 206.967" stroke="#003E46" stroke-width="3" />
    </svg>
)