import React from 'react';
import "./SectionHeading.scss";

const SectionHeading = ({ className, heading, subText }) => {
    return (
        <div className={`${className || ""} section_heading`}>
            <h2>{heading}</h2>
            {
                subText &&
                <div className="sub_text">
                    {
                        subText.map((item, index) => {
                            return (
                                <p key={index}>{item}</p>
                            )
                        })
                    }
                </div>
            }
        </div>
    )
}

export default SectionHeading