import React from "react";
import { useNavigate } from "react-router-dom";
import {
  LoadingIcon
} from "../../../assets/images/svgs/svgicons";
import "./Button.scss";

const Button = ({
  children,
  type,
  fluid,
  text,
  to,
  className,
  onClick,
  loading,
  disabled,
  img,
  ...rest
}) => {
  const navigate = useNavigate();

  return (
    <button
      {...rest}
      onClick={() => (to ? navigate(to || "") : onClick)}
      disabled={loading | disabled}
      className={`${className || ""} ${fluid ? "w-100" : ""} custom_btn`}
      type={type || "button"}
    >
      {loading ? (
        <span className="btn_loading">
          <LoadingIcon />
        </span>
      ) : (
        <>
          <span className="btn_text">{children}</span>
          <span className="btn_icon">{img}</span>
        </>
      )}
    </button>
  );
};

export default Button;
