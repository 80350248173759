export const SOCIAL_LINKS = {
    INSTAGRAM: "https://instagram.com/niche.nurses?igshid=OGQ5ZDc2ODk2ZA==",
    EMAIL_ID: "info@thenichenursing.ca",
    WHATSAPP: "https://wa.me/message/WREYH3YGFSP6I1",
    PHONE: "+1 (647) 200-0474",
    PHONE_SECOND: "+1 (647) 985-0474",
    WEBSITE: "https://www.thenichenursing.ca",
    FACEBOOK: "https://www.facebook.com/profile.php?id=100093594393660&mibextid=LQQJ4d",
    TELEGRAM: "https://t.me/(255) 352-6262",
    TWITTER: "http://www.twitter.com",
}

export const FORMIK_REGEX = {
    PHONE: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
    EMAIL: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
}

export const EMAIL_SERVICE = {
    SERVICE_ID: "thenichenursing",
    USER_TEMPLATE_ID: "useremailtemplate",
    ADMIN_TEMPLATE_ID: "adminemailtemplate",
    PUBLIC_KEY: "mGExeacpbKeG-8fBq",
}

