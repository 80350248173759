import React from 'react'
import Lottie from "lottie-react";

const LottieAnimation = ({ animation, ...rest }) => {
    return (
        <>
            <Lottie
                {...rest}
                animationData={animation}
            />
        </>
    )
}

export default LottieAnimation