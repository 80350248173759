import React, { useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { NavHashLink } from 'react-router-hash-link';
import logo from "../../../../assets/images/logos/logo.png";
import { IncludesPath } from "../../../../utils/utils";
import "./HeaderNavigation.scss";

const HeaderNavigation = ({ active, handleActive }) => {
  const dropdownRef = useRef();
  const handleDropdownClick = () => {
    dropdownRef.current.click();
    handleActive();
  };
  return (
    <>
      <span
        onClick={handleActive}
        className={`sidebar_overlay d-sm-none ${active ? "show" : ""}`}
      ></span>
      <div className={`header_nav ${active ? "active" : ""}`}>
        <NavHashLink
          onClick={handleDropdownClick}
          to="/"
          className="nav_logo d-sm-none"
        >
          <img src={logo} alt="logo" />
        </NavHashLink>
        <ul>
          <li>
            <NavHashLink onClick={handleActive} to="/">
              Home
            </NavHashLink>
          </li>
          <li>
            <NavHashLink onClick={handleActive} to="/about-us">
              About Us
            </NavHashLink>
          </li>
          <li>
            <Dropdown>
              <Dropdown.Toggle
                className={IncludesPath("services") ? "active" : ""}
                ref={dropdownRef}
              >
                Services
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <NavHashLink
                  onClick={handleDropdownClick}
                  to="/services#career-coaching"
                >
                  Career Coaching
                </NavHashLink>
                <NavHashLink
                  onClick={handleDropdownClick}
                  to="/services#exam-preparation"
                >
                  NCLEX-RN/PN/RexPN preparation
                </NavHashLink>
                <NavHashLink
                  onClick={handleDropdownClick}
                  to="/services#other-language-exams"
                >
                  IELTS/CELBAN/Other Language exams
                </NavHashLink>
                <NavHashLink
                  onClick={handleDropdownClick}
                  to="/services#resume-building-interview-preparation"
                >
                  Resume building/Interview preparation
                </NavHashLink>
                <NavHashLink
                  onClick={handleDropdownClick}
                  to="/services#recruitment-support"
                >
                  Recruitment Support
                </NavHashLink>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li>
            <NavHashLink onClick={handleActive} to="/contact-us">
              Contact Us
            </NavHashLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default HeaderNavigation;
