import React from "react";
import ContactusSection from "../../ui/ContactUs/ContactUs";
import "./ContactUs.scss";
const ContactUs = () => {
  return (
    <ContactusSection />
  );
};

export default ContactUs;
