import React from 'react'
import { Container } from 'react-bootstrap'
import SectionHeading from '../../../common/SectionHeading/SectionHeading'
import "./Team.scss";
import team1 from "../../../../assets/images/team/team1.PNG";
import team2 from "../../../../assets/images/team/team2.PNG";
import { Dots } from '../../../../assets/images/svgs/svgicons';

const Team = () => {
    return (
        <section className="team_sec">
            <Container className="position-relative">
                <span className="team_dots"><Dots /></span>
                <SectionHeading
                    heading="Meet our executive team"
                // subText={["We Bring a wealth of skills and experience from  a wide range of backgrounds."]}
                />
                <div className="team_box">
                    <div className="team_header">
                        <img src={team1} alt="gurpreet-kaur" />
                        <div>
                            <h3>Gurpreet Kaur, RN, BScN, MSN</h3>
                            <p>Chief Executive Officer (CEO)</p>
                        </div>
                    </div>
                    <div className="team_about">
                        <p>Meet Gurpreet Kaur, RN, BScN, MSN – a  dedicated Clinician, Consultant, Educator, and RN Entrepreneur. Now the Co-founder and CEO of The Niche Nursing Consultancy Inc., her thirteen-year nursing journey began as an IEN in Canada. With a Masters in Nursing from Toronto Metropolitan University specializing in geriatrics and clinical research, she's excelled in healthcare, education, leadership, and research across Canada. Currently a specialized clinician and consultant with Ontario Health, Gurpreet empowers nurses through tailored career coaching, licensing support, education and job readiness with her expertise honed over years.</p>
                        <p>Beyond her roles, Gurpreet cherishes motherhood, nature, and meaningful connections living in Brampton, Ontario with her family. Proficient in adult education and quality improvement she's dedicated to guiding IENs in their career transition. Join us for a transformative journey, led by Gurpreet's unwavering dedication to nursing excellence.</p>
                    </div>
                </div>
                <div className="team_box">
                    <div className="team_header">
                        <img src={team2} alt="harsimran-kaur" />
                        <div>
                            <h3>Harsimran Kaur, RN, BScN, MSN</h3>
                            <p>Chief Operating Officer (COO)</p>
                        </div>
                    </div>
                    <div className="team_about">
                        <p>Meet Harsimran Kaur, RN, BScN, MSN, RN Entrepreneur, Co-founder and Chief Operating Officer (COO) at Niche Nursing Consultancy Inc. With extensive expertise spanning over thirteen years, Harsimran is a Canadian Registered Nurse holding a Master's in nursing from Toronto Metropolitan University. Her prowess shines across nursing education, leadership, quality improvement, and government sector compliance. Harsimran's commitment as a Nursing Compliance Officer with Ontario's Ministry of Long-Term Care Homes showcases her commitment to high care standards.</p>
                        <p>As a devoted mom to two boys, Harsimran thrives in Toronto, embracing mindfulness and the beauty of nature alongside her husband. An advocate for evidence-based practices, she firmly believes in nurturing well-equipped nurses to enhance healthcare systems. Her journey of learning led her to discover her 'niche' – guiding fellow nurses with expertise and passion, particularly in assisting international nurses. She's driven to support Internationally Educated Nurses (IENs) through their journeys, making her an invaluable mentor in our team.</p>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default Team