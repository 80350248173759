import React from "react";
import "./MissionVision.scss";
import { Container } from "react-bootstrap";
import SectionHeading from "../../../common/SectionHeading/SectionHeading";
import BigText from "../../../common/BigText/BigText";

const MissionVision = () => {
  return (
    <section className="mission_vision">
      <Container>
        <div className="mission_box mission">
          <div className="mission_box_inner">
            <SectionHeading heading="Mission" />
            <BigText>
              Our mission is to provide comprehensive nursing career support
              and a wide array of related services to both local and
              internationally educated nurses, empowering them to flourish in
              successful nursing careers throughout Canada and North America.
            </BigText>
          </div>
        </div>
        <div className="mission_box vision">
          <div className="mission_box_inner">
            <SectionHeading heading="Vision" />
            <BigText>
              Our vision is to be leading global platform, fostering excellence
              in nursing practice, and nurturing a community of empowered
              nurses, dedicated to positively impacting healthcare worldwide.
            </BigText>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default MissionVision;
