import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CursiveLinesVertical, Dots } from '../../../../assets/images/svgs/svgicons';
import icon3 from "../../../../assets/images/values/collabration.png";
import icon5 from "../../../../assets/images/values/empathy.png";
import icon4 from "../../../../assets/images/values/honesty.png";
import icon2 from "../../../../assets/images/values/integrity.png";
import icon1 from "../../../../assets/images/values/nobility.png";
import SectionHeading from '../../../common/SectionHeading/SectionHeading';
import "./Values.scss";

const Values = () => {
    const values = [
        {
            icon: icon1,
            title: "nobility",
            text: "Nursing is often referred to as a “noble” profession. Here at Niche, we hold ourselves accountable to offer the best to our clients who set out to serve a noble cause and contribute to global health."
        },
        {
            icon: icon2,
            title: "integrity",
            text: "We act with transparency, build trust with our clients and follow through on our commitments, providing them with a seamless and exceptional experience.",
        },
        {
            icon: icon3,
            title: "collaboration",
            text: "We work together with our clients to carve out the best-individualized plan for their career journey.",
        },
        {
            icon: icon4,
            title: "honesty",
            text: "Our work is rooted in an honest and driven path to support our nursing colleagues to tap into their highest potential.",
        },
        {
            icon: icon5,
            title: "empathy",
            text: "We truly see the successes and struggles of a professional journey when in transition from one part of the world to another. We pride ourselves on standing alongside you through this transition.",
        },
    ];
    return (
        <section className="values_sec">
            <Container>
                <span className='right_dots'><Dots /></span>
                <SectionHeading
                    heading="Values"
                />
                <div className="position-relative">
                    <Row>
                        {
                            values.map(item => (
                                <Col key={item.title} md={6}>
                                    <div className="value_box">
                                        <img className="value_icon" src={item.icon} alt={`${item.title}-icon`} />
                                        <h3><span>{item.title[0]}</span>{item.title.slice(1)}</h3>
                                        <p>{item.text}</p>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                    <span className='bottom_dots'><Dots /></span>
                    <span className='cursive_lines'><CursiveLinesVertical /></span>
                </div>
            </Container>
        </section>
    )
}

export default Values