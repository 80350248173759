import React from 'react'
import { LoadingIcon, NavigateIcon } from '../../../assets/images/svgs/svgicons'
import "./SecondaryBtn.scss";
import { useNavigate } from 'react-router-dom';

const SecondaryBtn = ({ children, type, fluid, text, to, className, onClick, loading, disabled, ...rest }) => {
    const navigate = useNavigate();

    return (
        <button
            {...rest}
            onClick={() => to ? navigate(to || "") : onClick}
            disabled={loading | disabled}
            className={`${className || ""} secondary_btn`}
            type={type || "button"}
        >
            {
                loading ?
                    <span className="btn_loading">
                        <LoadingIcon />
                    </span>
                    :
                    <>
                        <span className='btn_text'>{children}</span>
                        <span className="btn_icon">
                            <NavigateIcon />
                        </span>
                    </>
            }
        </button>
    )
}

export default SecondaryBtn