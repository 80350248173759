import React from 'react'
import "./AboutBanner.scss";
import { Container } from 'react-bootstrap';
import SectionHeading from '../../../common/SectionHeading/SectionHeading';

const Aboutbanner = () => {
    return (
        <section className='about_banner'>
            <Container>
                <SectionHeading
                    className='text-center'
                    heading="About Us"
                    subText={[
                        "Welcome to The Niche Nursing Consultancy Inc., a Canadian registered organization dedicated to empowering nurses worldwide with professional career consultation and education services. We support internationally educated nurses in achieving successful nursing careers in Canada and North America. With a specialized career pathway designed to help you acquire your nursing license and explore diverse opportunities, we are here to guide you on your journey towards finding your unique “niche”  in the nursing profession.",
                        "For Canadian and North American registered nurses, we offer professional support to explore and enhance your nursing career options, ensuring you discover the path that resonates with your passion and expertise. Our team of Canadian registered nurses are committed to mentoring and guiding both novice and seasoned nurses as they advance their careers in various specialties.",
                        "At Niche Nursing Consultancy, we believe that every nurse has the potential to thrive and make a significant impact in the healthcare industry. Connect with us to learn more about how we can help you navigate your nursing journey and shape a fulfilling and rewarding career. Together, let’s embrace the possibilities and unlock the potential within you.",
                    ]}
                />
            </Container>
        </section>
    )
}

export default Aboutbanner