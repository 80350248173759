import React, { useEffect } from 'react'
import "./HomeLayout.scss";
import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../../common/Footer/Footer';
import Header from '../../common/Header/Header';
import SocialHeader from '../../common/SocialHeader/SocialHeader';
import SocialSidebar from '../../common/SocialSidebar/SocialSidebar';

const HomeLayout = () => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])
    return (
        <main className="home_layout">
            <SocialHeader />
            <Header />
            <SocialSidebar />
            <Outlet />
            <Footer />
        </main>
    )
}

export default HomeLayout