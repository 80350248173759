import React from 'react';
import "./Textarea.scss";
import { ErrorMessage, Field } from 'formik';
import ErrorMsg from '../ErrorMsg/ErrorMsg';

const Textarea = ({ name, label, className, ...rest }) => {
    return (
        <div className={`custom_area ${className || ""}`}>
            {
                label &&
                <label htmlFor={name}>{label}</label>
            }
            <Field {...rest} as="textarea" name={name} id={name} />
            {
                <ErrorMessage
                    name={name}
                    component={ErrorMsg}
                />
            }
        </div>
    )
}

export default Textarea