import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from "../../../assets/images/logos/logo.png";
import Button from '../Button/Button';
import "./Header.scss";
import HeaderNavigation from './HeaderNavigation/HeaderNavigation';
import Toggler from './Toggler/Toggler';

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [active, setActive] = useState(false);
  window.onscroll = () => setScrolled(window.scrollY > 0)

  return (
    <>
      <header className={`header ${scrolled ? "scrolled" : ""}`}>
        <Container>
          <div className="header_inner">
            <Link to="/" className='header_logo'>
              <img src={logo} alt="" />
            </Link>
            <HeaderNavigation active={active} handleActive={() => setActive(!active)} />
            <Button
              to="/contact-us"
              className="action_btn"
            >
              Contact Us
            </Button>
            <Toggler active={active} handleActive={() => setActive(!active)} />
          </div>
        </Container>
      </header>
    </>
  )
}

export default Header